

<div id="installedPackages">
    <pager 
        [dataSource]="dataSource"
        (focusRelinquished)="pagerFocusRelinquished($event)"
    >
    </pager>
    <p>{{ status }}</p>
    <p>Pager Position: {{ this.dataSource.page }}</p>
    <packages-page
        (focusRelinquished)='packagesPageFocusRelinquished($event)'
        [items]='dataSource.view | async'>
    </packages-page>
</div>
