import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DuplexChannelEventArgs } from './framework/duplexChannelEventArgs';
import { DuplexTypedMessageSender, TypedResponseReceivedEventArgs } from './framework/duplexTypedMessageSender';
import { WebSocketDuplexOutputChannel } from './framework/webSocketDuplexOutputChannel';
import { OutputChannel } from './framework/outputChannel';
import { Injectable } from '@angular/core';
import * as LaqorrProtobuf from "../laqorrProtobuf";
import { Serializers } from "./serializers"
import { ServerEndpointProvider } from 'src/serverEndpointProvider';
import { FiniteStateMachineStateManager } from '../finiteStateMachineStateManager';
import { LoggerService } from 'src/loggerService';

const LOG_TAG = "clientRequestDispatcher";

function serializeMessage(message: LaqorrProtobuf.Message) : Promise<ArrayLike<number>> {
    return Serializers.Message.serialize(message);
}

function asUint8Array(v: ArrayBuffer | ArrayLike<number>, loggerService: LoggerService) : Uint8Array {
    if(v instanceof Uint8Array) {
        return v;
    } else {
        try {
            return new Uint8Array(v);
        } catch(e) {
            loggerService.logError(LOG_TAG, 'The Uint8Array constructor threw an error!');
            loggerService.logError(LOG_TAG, e);
            throw e;
        }
    }
}

async function deserializeMessage(arrayBuffer : ArrayBuffer, loggerService: LoggerService) : Promise<LaqorrProtobuf.Message> {
    //return Serializers.Message.deserialize(arrayBuffer);
    let protoType:protobuf.Type = undefined;
    try {
        protoType = await Serializers.Message.getProtobufType();
    } catch(error) {
        loggerService.logError(LOG_TAG, 'Error thrown from Serializers.Message.getProtobufType()');
        throw error;
    }
    let uint8Array: Uint8Array = undefined;
    try {
        uint8Array = asUint8Array(arrayBuffer, loggerService);
    } catch(error) {
        loggerService.logError(LOG_TAG, 'Error thrown from asUint8Array');
        throw error;
    }

    let messageT:protobuf.Message<{}> = null;
    try {
        messageT = protoType.decode(uint8Array);
    } catch(error) {
        loggerService.logError(LOG_TAG, 'Error thrown from protoType.decode');
        throw error;
    }
    const message:LaqorrProtobuf.Message = <LaqorrProtobuf.Message><any>messageT;
    return message;

} 

interface ClientRequestDispatcherStateContext {
    readonly endpointUri : string;
    duplexTypedMessageSender : DuplexTypedMessageSender;
    outputChannel : OutputChannel;
    loggerService: LoggerService;
}

interface ClientRequestDispatcherStatus {
    isAvailable: boolean;
    canOpen: boolean;
    canClose: boolean;
    isClosed: boolean;
    description: string;
}

type MessageType = ArrayBuffer | ArrayLike<number>;

abstract class ClientRequestDispatcherState {
    constructor(protected readonly context: ClientRequestDispatcherStateContext) {
    }
    abstract endpointUriChanged() : ClientRequestDispatcherState;
    abstract openConnection() : ClientRequestDispatcherState;
    closeConnection() : ClientRequestDispatcherState {
        throw "in the wrong state to close the connection";
    }
    connectionOpened(duplexChannelEventArgs : DuplexChannelEventArgs) : ClientRequestDispatcherState {
        throw "received a connectionOpened event when not in the opening state";
    }
    connectionClosed(duplexChannelEventArgs: DuplexChannelEventArgs) : ClientRequestDispatcherState {
        return this.context.endpointUri
            ? new StateClosed(this.context)
            : new StateAwaitingEndpointAddress(this.context);
    }
    connectionError(args: Event) : ClientRequestDispatcherState {
        return this.context.endpointUri
            ? new StateClosed(this.context, "An error occurred when attempting to open the connection")
            : new StateAwaitingEndpointAddress(this.context);
    }
    abstract sendRequestMessage(message: MessageType) : ClientRequestDispatcherState;

    abstract get status() : ClientRequestDispatcherStatus;
}

class StateClosing extends ClientRequestDispatcherState {
    endpointUriChanged() : ClientRequestDispatcherState {
        throw "TODO: Handle the situation where the endpoint URI changes while in the open state";
    }  
    openConnection() : ClientRequestDispatcherState {
        throw "TODO: what happens if they ask to open the connection when closing";
    }
    sendRequestMessage(message: ArrayBuffer) : ClientRequestDispatcherState {
        throw "TODO: what happens if sendRequestMessage is called while closing";   
    }
    get status() : ClientRequestDispatcherStatus {
        return {
            canOpen: false,
            canClose: false,
            isClosed: false,
            isAvailable: true,
            description: "Closing the connection..."
        }
    }
}

class StateOpen extends ClientRequestDispatcherState {
    endpointUriChanged() : ClientRequestDispatcherState {
        return this.closeConnection();
    }
    openConnection() : ClientRequestDispatcherState {
        return this;
    }
    sendRequestMessage(message: ArrayBuffer) : ClientRequestDispatcherState {
        this.context.duplexTypedMessageSender.sendRequestMessage(message);  
        return this;      
    }
    closeConnection() : ClientRequestDispatcherState {
        this.context.outputChannel.closeConnection();
        return new StateClosing(this.context);
    }

    get status() : ClientRequestDispatcherStatus {
        return {
            canOpen: false,
            canClose: true,
            isClosed: false,
            isAvailable: true,
            description: `Connection is open on ${this.context.endpointUri}`
        };
    }
}

class StateOpening extends ClientRequestDispatcherState {
    constructor(context: ClientRequestDispatcherStateContext, private readonly pendingMessages: MessageType[]) {
        super(context);
    }
    endpointUriChanged() : ClientRequestDispatcherState {
        throw "TODO: Handle the situation where the endpoint URI changes while in the opening state";
    }
    openConnection() : ClientRequestDispatcherState {
        return this;
    }
    connectionOpened(duplexChannelEventArgs: DuplexChannelEventArgs) : ClientRequestDispatcherState {
        for(let message of this.pendingMessages) {
            this.context.duplexTypedMessageSender.sendRequestMessage(message);
        }
        return new StateOpen(this.context);
    }

    sendRequestMessage(message: ArrayBuffer) : ClientRequestDispatcherState {
        return new StateOpening(this.context, [...this.pendingMessages, message]);
    }

    get status() : ClientRequestDispatcherStatus {
        return {
            canOpen: false,
            canClose: false,
            isClosed: false,
            isAvailable: true,
            description: `Opening connection to ${this.context.endpointUri}`
        };
    }
}

class StateClosed extends ClientRequestDispatcherState {
    constructor(context: ClientRequestDispatcherStateContext, private readonly extraMessage? : string) {
        super(context);
    }
    endpointUriChanged() : ClientRequestDispatcherState {
        return this.context.endpointUri
            ? this
            : new StateAwaitingEndpointAddress(this.context);
    } 
    sendRequestMessage(message: ArrayBuffer) : ClientRequestDispatcherState {
        return this.openConnection([message]);
    }
    openConnection(pendingMessages = new Array<MessageType>(0)) : ClientRequestDispatcherState {
        this.context.outputChannel = new WebSocketDuplexOutputChannel(
            this.context.endpointUri, 
            null,
            this.context.loggerService
        );
        return new StateOpening(this.context, pendingMessages);
    }
    get status() : ClientRequestDispatcherStatus {
        return {
            canOpen: true,
            canClose: false,
            isClosed: true,
            isAvailable: true,
            description: this.extraMessage
                ? `Known endpoint is: ${this.context.endpointUri}. ${this.extraMessage}`
                : `Known endpoint is ${this.context.endpointUri}. Connection is closed`
        };
    }
}

class StateAwaitingEndpointAddress extends ClientRequestDispatcherState {
    private readonly pendingMessages: MessageType[];

    constructor(context: ClientRequestDispatcherStateContext, pendingMessages? : MessageType[]) {
        super(context);
        this.pendingMessages = pendingMessages;
    }

    endpointUriChanged() : ClientRequestDispatcherState {
        if(this.context.endpointUri) {
            const newState = new StateClosed(this.context);
            if(this.pendingMessages && this.pendingMessages.length) {
                return newState.openConnection(this.pendingMessages);
            } else {
                return newState;
            }
        } else {
            return this;
        }
    }
    openConnection() : ClientRequestDispatcherState {
        throw "Unable to open a connection as no endpoint is available";
    }
    sendRequestMessage(message: ArrayBuffer) : ClientRequestDispatcherState {
        console.log('sendRequestMessage invoked while awaiting endpoint address. Storing the message to send when available');
        const pendingMessages = this.pendingMessages ? [...this.pendingMessages, message] : [message];
        return new StateAwaitingEndpointAddress(this.context, pendingMessages);
    }
    get status() : ClientRequestDispatcherStatus {
        return {
            canOpen: false,
            canClose: false,
            isClosed: true,
            isAvailable: false,
            description: `Waiting for an endpoint address`
        };
    }
}

@Injectable({
    providedIn: 'root'
})
export class ClientRequestDispatcher implements ClientRequestDispatcherStateContext {

    endpointUri : string;
    lastCommunicationTime: Date;

    readonly duplexTypedMessageSender;
    anOutputChannel : OutputChannel;

    get outputChannel() : OutputChannel {
        return this.anOutputChannel;
    }

    private outputChannelConnectionOpenedSubscription : Subscription;
    private outputChannelConnectionClosedSubscription : Subscription;
    private outputChannelErrorSubscription : Subscription;

    set outputChannel(channel: OutputChannel) {
        if(this.outputChannelConnectionOpenedSubscription) {
            this.outputChannelConnectionOpenedSubscription.unsubscribe();
            this.outputChannelConnectionOpenedSubscription = null;
        }
        if(this.outputChannelConnectionClosedSubscription) {
            this.outputChannelConnectionClosedSubscription.unsubscribe();
            this.outputChannelConnectionClosedSubscription = null;
        }
        if(this.outputChannelErrorSubscription) {
            this.outputChannelErrorSubscription.unsubscribe();
            this.outputChannelErrorSubscription = null;
        }
        if(this.anOutputChannel) {
            this.duplexTypedMessageSender.detachDuplexOutputChannel();
        }

        this.anOutputChannel = channel;
        this.outputChannelConnectionOpenedSubscription = this.anOutputChannel.onConnectionOpened.subscribe({
            next: (args) => {
                this.transition(s => s.connectionOpened(args));
            }
        });
        this.outputChannelConnectionClosedSubscription = this.anOutputChannel.onConnectionClosed.subscribe({
            next: (args) => {
                // A workaround to a problem that the connectionClosed event can fire
                // after connectionClosing
                window.setTimeout(
                    () => {
                        this.transition(s => s.connectionClosed(args));
                        this.connectionClosedSubject.next();
                    }, 
                    0
                );
            }
        });
        this.outputChannelErrorSubscription = this.anOutputChannel.onConnectionError.subscribe({
            next: (args: Event) => {
                this.transition(s => s.connectionError(args));
                this.connectionErrorSubject.next();
            }
        });
        this.duplexTypedMessageSender.attachDuplexOutputChannel(this.anOutputChannel);
    }

    private readonly finiteStateMachineStateManager : FiniteStateMachineStateManager<ClientRequestDispatcherState>;
    get status() : Observable<ClientRequestDispatcherStatus> {
        return this.finiteStateMachineStateManager.currentState.pipe(map(s => s.status));
    }

    private readonly connectionClosedSubject = new Subject<void>();
    get connectionClosed() : Observable<void> {
        return this.connectionClosedSubject.asObservable();
    }

    private readonly connectionErrorSubject = new Subject<Event>();
    get connectionError() : Observable<Event> {
        return this.connectionErrorSubject.asObservable();
    }

    private transition(t : (oldState: ClientRequestDispatcherState) => ClientRequestDispatcherState | Promise<ClientRequestDispatcherState>) {
        this.finiteStateMachineStateManager.transition(t);
    }

    public constructor(
        private endpointProvider: ServerEndpointProvider,
        public loggerService: LoggerService
    ) {
        this.duplexTypedMessageSender = new DuplexTypedMessageSender(loggerService);
        this.finiteStateMachineStateManager = new FiniteStateMachineStateManager<ClientRequestDispatcherState>(
            new StateAwaitingEndpointAddress(this)
        );
        // Does it matter that we never unsubscribe from this?
        this.endpointProvider.endpoints.subscribe({
            next : endpoints => {
                if(endpoints.socketEndpoints.length) {
                    this.endpointUri = endpoints.socketEndpoints[0].uri;
                } else {
                    this.endpointUri = null;
                }
                this.transition(s => s.endpointUriChanged());
            }
        });    
        this.duplexTypedMessageSender.onResponseReceived = (args) => this.onResultReceived(args);
    }

    connectionOpenedListeners: Array<() => void> = [];

    addConnectionOpenedListener(listener : ()=>void) {
        this.connectionOpenedListeners.push(listener);
    };

    openConnection()  {
        this.transition(s => s.openConnection());
    }

    closeConnection() {
        this.transition(s => s.closeConnection());
    }
    
    async sendMessage(message: LaqorrProtobuf.Message) : Promise<void> {
        try {
            const protoMessageSerialized = await serializeMessage(
                message
            );
            this.transition(
                s => s.sendRequestMessage(protoMessageSerialized)
            );
        } catch(error) {
            this.loggerService.logError(LOG_TAG, 'clientDispatcher.sendMessage threw an error!');
            this.loggerService.logError(LOG_TAG, error);
        }
    }

    private readonly messageReceivedSubject = new Subject<LaqorrProtobuf.Message>();

    public get messageReceived() : Observable<LaqorrProtobuf.Message> {
        return this.messageReceivedSubject;
    }

    private async onResultReceived(responseMessage: TypedResponseReceivedEventArgs) {
        if(!responseMessage.ResponseMessage) {
            this.loggerService.logError(LOG_TAG, 'clientRequestDispathcer.onResultReceived. responseMessage.ResponseMessage is falsey!');
            this.loggerService.logError(LOG_TAG, `typeof(responseMessage.ResponseMessage) == ${typeof(responseMessage.ResponseMessage)}`);
            this.loggerService.logError(LOG_TAG, `responseMessage.ResponseMessage = ${responseMessage.ResponseMessage}`);
        }
        let message:LaqorrProtobuf.Message = null;
        try {
            message = await deserializeMessage(responseMessage.ResponseMessage, this.loggerService);
        } catch(error) {
            this.loggerService.logError(LOG_TAG, 'ClientRequestDispatcher.onResultReceived threw an error when calling deserializeMessage');
            this.loggerService.logError(LOG_TAG, error);
        }

        try {
            this.lastCommunicationTime = new Date();
            this.messageReceivedSubject.next(message);    
        } catch(error) {
            this.loggerService.logError(LOG_TAG, 'ClientRequestDispatcher.onResultReceived threw an error');
            this.loggerService.logError(LOG_TAG, error);
            this.loggerService.logError(LOG_TAG, error.stack);
        }
    }
}