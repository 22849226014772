<h1>Installed Applications</h1>
<p>
    The installed applications view. There are {{  (dataSource.data).length }} applications.
</p>
<pager 
    [dataSource]="dataSource"
    (focusRelinquished)="pagerFocusRelinquished($event)">
</pager>
<applications-page
    (focusRelinquished)='applicationsPageFocusRelinquished($event)'
    [items]='dataSource.view | async'>
</applications-page>