<a 
    class="installedPackageTile"
    [class]="{focused: hasFocus}"
    routerLink="../package/{{ package.id }}"    
    #packageHyperlink
>
<div 
    class="iconFrame"
    [style.background-image]="'url(' + package.iconPath + ')'"
>
</div>
<div class="tileName">
    {{ package.name }}
</div>
</a>