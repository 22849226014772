import { Component } from '@angular/core';
import { LoggerService } from '../../../loggerService';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


interface LogDisplayEntry {
    time: string,
    source: string,
    type: string,
    content: string
}

function pad(n: number) : string {
    if (n < 10) {
        return `0${n}`;
    } else {
        return `${n}`;
    }
}

function formatDateX(d: Date) {
    return `${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
}

@Component({
    "templateUrl": "logger-display-view.component.html",
    "selector": "logger-display-view",
    "styleUrls": ["./logger-display-view.component.scss"]
})
export class LoggerDisplayViewComponent {

    public readonly logs$: Observable<LogDisplayEntry[]>;

    constructor(readonly logger: LoggerService) {
        this.logs$ = logger.logs.pipe(
            map(l => l.map(
                entry => {
                    return {
                        time: moment(entry.dateTime).format("HH:mm:ss"),
                        source: entry.source,
                        type: entry.type,
                        content: entry.content
                    };
                }
            ))
        );
    }

}