

<label>Laqorr Server Address</label>
<input type="text" 
    #webServerAddress
    [value]="serverAddress"
    (input)="serverAddress = $event.target.value"
    (keydown)="webServerInputKeyDown($event)"
/>
<button #webServerAddressSubmitButton type="button" (click)="serverAddressSelected.emit(serverAddress)">
    Submit
</button>