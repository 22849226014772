import * as LaqorrProtobuf from "./laqorrProtobuf";
import { Injectable } from '@angular/core';
import { FileManager, fileManager } from "./fileManager";
import { Logger } from "./logger";
import { LoggerService } from "./loggerService";
import { FileUtils } from "./fileUtils";
import { md5 } from "./md5";
import { MediaPlayerFileSystem } from "./mediaPlayerFileSystem";
import { BehaviorSubject, Observable } from 'rxjs';


export enum FileDownloadClientState { IDLE, TRANSFERRING, COPYING }

export interface FileDownloadClientStatus {
	state: FileDownloadClientState;
	fileName?: string;
	fileSize?: number;
	amountDownloadedSoFar?: number;
}

const IdleStatus : FileDownloadClientStatus = {
	state: FileDownloadClientState.IDLE
};

interface FileSizeAndMd5 {
	file: tizen.File;
	size: number;
	md5: string;
}

const componentName = "FileDownloadClient";

// I do not know what the max file size should be
// but it did fail on a file that was 43,625,655
const MD5_CHECK_MAX_FILE_SIZE = 25000000;
const LOGGER_SOURCE = "FileDownloadClient";

@Injectable({
    providedIn: "root"
})
export class FileDownloadClient {

	private temporaryFolderName: string = fileManager.temporaryFiles;
    private destinationResolver: DestinationResolver = new DestinationResolver();

	private readonly fileDownloadClientStatusSubject = new BehaviorSubject<FileDownloadClientStatus>(IdleStatus);

	get status$(): Observable<FileDownloadClientStatus> {
		return this.fileDownloadClientStatusSubject;
	}

    constructor(private mediaPlayerFileSystem: MediaPlayerFileSystem, private readonly loggerService: LoggerService, private readonly logger: Logger) {
    }

	// Not sure how well this will deal with really large files
	// 
	// For large files it might be better if it did not load the 
	// entire file into memory, but just read one block of bytes at a time
	//
	private static async checkIfFileExistsAndMatchesSizeAndChecksum(
		folderEntry: tizen.File | DirectoryEntry,
		fileSubPath: string, 
		checksum: string, 
		totalBytes: number,
		loggerService: LoggerService
	): Promise<boolean> {
		try {
			const entry: tizen.File | FileEntry = await FileUtils.getTizenFile(folderEntry, fileSubPath, { } );
			const fileSize = await FileUtils.getFileSize(entry); 
			if (fileSize !== totalBytes) {
				return false;
			} else {
				if (fileSize > MD5_CHECK_MAX_FILE_SIZE) {
					return true;
				} else {
					const generatedChecksum = await FileUtils.getFileMd5ByChunks(entry, loggerService);
					return FileUtils.compareMd5s(generatedChecksum, checksum);
				}
			}
		} catch (e) {
			// This happens if the file does not exist
			return false;
		}
	}

	private writeByteArrayToFile(fileEntry: tizen.File | FileEntry, data: Array<number>): Promise<void> {
		if(FileManager.isTizenFile(fileEntry)) {
			return new Promise(function (resolve, reject) {
				fileEntry.openStream(
					"a",
					(fs) => {
						try {
							fs.writeBytes(Array.from(data));
							resolve();
						} catch (e) {
							reject(e);
						} finally {
							fs.close();
						}
					},
					reject
				);
			});
		} else {
			return new Promise<void>(
				(resolve, reject) => {
					fileEntry.createWriter(
						fileWriter => {
							fileWriter.onwriteend = e => {
								resolve();
							};
							fileWriter.onerror = (e:ProgressEvent<FileWriter>) => {
								if(e.target.error) {
									this.logger.error(componentName, `fileWrite failed: ${e.target.error}`);
								} else {
									this.logger.error(componentName, `fileWrite failed: ${e}`)
								}
								reject(e);
							}
							try {
								const uint8Array = new Uint8Array(data);
								const blob:Blob = new Blob([uint8Array.buffer]); //  = Blob([data]);
								fileWriter.seek(fileWriter.length);
								fileWriter.write(blob);
							} catch(e) {
								reject(e);
							}
						},
						reject
					);
				}
			);
		}
    }

	private lengthDownloadedSoFar = 0;



    async startDownload(downloadParameters : LaqorrProtobuf.ClientInterface.DownloadParameters) : Promise<number> {
		this.lengthDownloadedSoFar = 0;
        // Must work out if a file with this name already appears in this direcotry
        // if so, return the totalBytes
        const checksum = downloadParameters.Checksum;
		const file = downloadParameters.File.replace("\\", "/");
		const totalBytes = downloadParameters.TotalBytes;
		const cmsFileType = downloadParameters.MediaType;

		this.fileDownloadClientStatusSubject.next({
			state: FileDownloadClientState.TRANSFERRING,
			amountDownloadedSoFar: 0,
			fileName: file,
			fileSize: totalBytes
		});
		// Get an existing directory for the CmsFileType
		const mediaPlayerFolder = this.destinationResolver.convertCmsFileTypeToMediaFolder(cmsFileType, file);
		const folderEntry = await fileManager.getMediaPlayerFolderEntry(mediaPlayerFolder);

		const fileAlreadyMatchesChecksum = await FileDownloadClient.checkIfFileExistsAndMatchesSizeAndChecksum(
			folderEntry,
			file,
			checksum,
			totalBytes,
			this.loggerService
		);
		if (fileAlreadyMatchesChecksum) {
			return totalBytes;
		} else {
			try {
				// TODO: Check if the bytes available in the destination folder is adequate
				const fileEntry = await this.getExistingTemporaryFile(file);
				//console.log('The intermediate file ' + fileEntry.fullPath + ' already exists!');
				if(!FileManager.isTizenFile(fileEntry)) {
					throw `startDownload not supported for the non-tizen file system`;
				}
				const generatedMd5 = await FileUtils.getFileMd5ByChunks(fileEntry, this.loggerService);
				const fileInfo: FileSizeAndMd5 = { size: fileEntry.fileSize, md5: generatedMd5, file: fileEntry };
				// TODO: If the intermediate file already exists and matches the checksum, invoke completeDownload_Ex
				// console.log('the file ' + fileEntry.fullPath + ' has a file size of ' + fileSize);
				const md5sMatch = FileUtils.compareMd5s(fileInfo.md5, checksum);
				if (md5sMatch) {
					return fileInfo.size;
				} else {
					return await new Promise(function (resolve, reject) {
						fileInfo.file.parent.deleteFile(
							fileInfo.file.fullPath,
							function () {
								resolve(0);
							},
							function (e) {
								console.error("cannot delete bad temporary file");
								console.error(e);
								resolve(fileInfo.size);
							}
						);
					});
				}
			} catch (e) {
				// See https://developer.mozilla.org/en-US/docs/Web/API/DOMError
				// I wonder if these strings are defined anywhere
				if (e && (e.name === "NotFoundError")) {
					//console.log('The intermediate file ' + file + ' does not already exist');
				} else {
					// console.log('Unexpected error thrown from getExistingTemporaryFile: ' + e);
				}
				return 0;
			}
		}
	}
	
	private async getOrCreateTemporaryFile(temporaryFilePath: string): Promise<tizen.File | FileEntry> {
		const separatedByFolderPath = temporaryFilePath.split('\\');
		const subFolders = [this.temporaryFolderName].concat(separatedByFolderPath.slice(0, separatedByFolderPath.length - 1));
		let folderReady = fileManager.fileSystemReady;
		folderReady = subFolders.reduce(function (accumulatingPromise: Promise<tizen.File>, path: string) {
			return FileUtils.chainSubFolder(accumulatingPromise, path, { create: true, exclusive: false });
		}, folderReady);

		let fileName = separatedByFolderPath[separatedByFolderPath.length - 1];
		const folderEntry = await folderReady;
		return await FileUtils.getTizenFile(
			folderEntry,
			fileName,
			{ create: true, exclusive: false }
		);
	}

	private async getExistingTemporaryFile(temporaryFilePath: string): Promise<tizen.File | FileEntry> {
		const separatedByFolderPath = temporaryFilePath.split('\\');
		const subFolders = [this.temporaryFolderName].concat(separatedByFolderPath.slice(0, separatedByFolderPath.length - 1));
		let folderReady = fileManager.fileSystemReady;
		folderReady = subFolders.reduce(function (accumulatingPromise: Promise<tizen.File>, path: string) {
			return FileUtils.chainSubFolder(accumulatingPromise, path, { create: false, exclusive: false });
		}, folderReady);
		const fileName = separatedByFolderPath[separatedByFolderPath.length - 1];
		const folderEntry = await folderReady;
		return await FileUtils.getTizenFile(folderEntry, fileName, { create: false });
	}

	async receiveBytes(receiveBytesParameters: LaqorrProtobuf.ClientInterface.ReceiveBytesParameters): Promise<boolean> {
		const fileSubPath = receiveBytesParameters.File;
		const sequenceId = receiveBytesParameters.SequenceId;
		const checksum = receiveBytesParameters.Checksum;
		const cmsFileType = receiveBytesParameters.MediaType;
		const generatedMd5 = md5(receiveBytesParameters.Data);

		this.lengthDownloadedSoFar += receiveBytesParameters.Data.length;

		const existingState = this.fileDownloadClientStatusSubject.value;
		this.fileDownloadClientStatusSubject.next({
			state: FileDownloadClientState.TRANSFERRING,
			amountDownloadedSoFar: this.lengthDownloadedSoFar,
			fileName: existingState.fileName,
			fileSize: existingState.fileSize
		});

		if (!FileUtils.compareMd5s(generatedMd5, checksum)) {
			console.log("FileDownloadClient.receiveBytes. checksum is '" + checksum + "', generatedMd5 is '" + generatedMd5 + "'");
			return false;
		}
		const temporaryFile = await this.getOrCreateTemporaryFile(fileSubPath);
		try {
			await this.writeByteArrayToFile(temporaryFile, receiveBytesParameters.Data);
		} catch(e) {
			let message:string;
			if(typeof(e.name) == 'string') {
				message = e.name;
			} else if (typeof(e.message) === 'string') {
				message = e.message;
			} else if (typeof(e) === 'string') {
				message = e;
			}
			console.error(`writeByteArrayToFile failed. message: '${message}'`);
		}
		return true;
	}

	// There are probably many instances of this method. Must find them and remove duplication
    async clearInProgressFolder(): Promise<boolean> {
        const temporaryFolderName = this.temporaryFolderName;
		const rootFolder = await fileManager.fileSystemReady;
        const temporaryFolder = await FileUtils.getDirectory(rootFolder, temporaryFolderName, true);

        const directoryEntries = await FileUtils.readDirectoryEntries(temporaryFolder);
        for (let entry of directoryEntries) {
            await FileUtils.deleteFileOrDirectory(entry);
        }
        return true;
	}

	async completeDownload(downloadParameters: LaqorrProtobuf.ClientInterface.DownloadParameters) : Promise<boolean> {
		const checksum = downloadParameters.Checksum;
		const file = downloadParameters.File;
		const totalBytes = downloadParameters.TotalBytes;
		const cmsFileType = downloadParameters.MediaType;
		const existingState = this.fileDownloadClientStatusSubject.value;
		this.fileDownloadClientStatusSubject.next({
			state: FileDownloadClientState.COPYING,
			amountDownloadedSoFar: this.lengthDownloadedSoFar,
			fileName: existingState.fileName,
			fileSize: existingState.fileSize
		});		
		
		const fileEntry = await this.getExistingTemporaryFile(file);
		const fileLength = await FileUtils.getFileSize(fileEntry);
		if(!(fileLength === downloadParameters.TotalBytes)) {
			const errorMessage = `completeDownload: the required file length is ${downloadParameters.TotalBytes}, while the actual length is ${fileLength}`;
			console.error(errorMessage);
			this.loggerService.logError(LOGGER_SOURCE, errorMessage);
		}


		try {
			if (fileLength < MD5_CHECK_MAX_FILE_SIZE) {
				const timeBefore = (new Date()).getTime();
				const generatedMd5 = await FileUtils.getFileMd5ByChunks(fileEntry, this.loggerService);
				const isMatch = FileUtils.compareMd5s(generatedMd5, checksum);
				if (!isMatch) {
					this.logger.error(LOGGER_SOURCE, `FileDownloadClient - The downloaded file does not match expected MD5 for ${downloadParameters.File}`);
					return false;
				} else {
					const timeAfter = (new Date()).getTime();
					this.logger.debug(LOGGER_SOURCE, `FileDownloadClient - performed MD5 check on file of size ${fileLength}. Milliseconds duration: ${timeAfter - timeBefore}`)
				}
			} else {
				this.logger.debug(LOGGER_SOURCE, `Not performing an MD5 comparison on file ${downloadParameters.File} as the size is too big. (${fileLength} bytes)`)
			}
			try {
				const mediaFolder = await this.destinationResolver.workOutMediaFolder(
					cmsFileType,
					fileEntry
				);
				let getFolderEntry = fileManager.getMediaPlayerFolderEntry(mediaFolder);
				const fileNameSplit = file.split('\\');
				const folders = fileNameSplit.slice(0, fileNameSplit.length - 1);
				getFolderEntry = folders.reduce(async (p, f) => {
					const fe = await p;
					return await FileUtils.getTizenDirectory(fe, f, { create: true, exclusive: false });
				}, getFolderEntry);
				const mediaFolderEntry = await getFolderEntry;
				// TODO: If the file exists already, delete it!
				//console.log("FileDownloadClient.completeDownload - retreived the destination folder entry '" + mediaFolderEntry.fullPath + "'");
				// But now, lets move the file
				this.loggerService.logDebug(LOGGER_SOURCE, `fileDownloadClient.completeDownload. Moving file to folder ${mediaFolderEntry.fullPath}`);
				const nameOnly = file.substr(file.lastIndexOf('\\') + 1);
				try {
					const createdFileEntry = await FileUtils.moveFile(fileEntry, mediaFolderEntry, nameOnly);
					if (this.mediaPlayerFileSystem) {
						try {
							await this.mediaPlayerFileSystem.fireFileSavedEvent(createdFileEntry);
						} catch (e) {
							console.error(`FileDownloadClient.completeDownload - error when attempting to get handle to the newly created file: ${e}`);
							this.loggerService.logError(LOGGER_SOURCE, `FileDownloadClient.completeDownload - error when attempting to get handle to the newly created file: ${e}`);
						}
					}
					return true;
				} catch (e) {
					console.error(`FileDownloadClient - Move file failed. file is ${nameOnly}, error is ${e}`);
					this.loggerService.logError(LOGGER_SOURCE, `FileDownloadClient - Move file failed. file is ${nameOnly}, error is ${e}`);
					throw e;
				}
			} catch (e) {
				return false;
			}
		} catch (e) {
			console.error('FileDownloadClient.completeDownload failed.- unable to get file handle to "' + file + '"');
			this.loggerService.logError(LOGGER_SOURCE, 'FileDownloadClient.completeDownload failed.- unable to get file handle to "' + file + '"');
			this.loggerService.logError(LOGGER_SOURCE, e);
			return false;
		} finally {
			this.fileDownloadClientStatusSubject.next(IdleStatus);	
		}
	}
}

class DestinationResolver {
	private getFileManagerFolderId(cmsFileType: LaqorrProtobuf.ClientInterface.CmsFileType) {
		switch (cmsFileType) {
			case LaqorrProtobuf.ClientInterface.CmsFileType.Video:
				return fileManager.mediaPlayerFolder.Video;
			case LaqorrProtobuf.ClientInterface.CmsFileType.Music:
				return fileManager.mediaPlayerFolder.Music;
			case LaqorrProtobuf.ClientInterface.CmsFileType.AudioMessage:
				return fileManager.mediaPlayerFolder.AudioMessage;
			case LaqorrProtobuf.ClientInterface.CmsFileType.AudioAdvert:
				return fileManager.mediaPlayerFolder.AudioAdvert;
			case LaqorrProtobuf.ClientInterface.CmsFileType.PlaylistMasterFile:
				// We have to guess
				return fileManager.mediaPlayerFolder.AudioPlaylist;
			case LaqorrProtobuf.ClientInterface.CmsFileType.MediaTagFile:
				// Not even sure what this is. It may have become redundant due to the music whitelist
				return fileManager.mediaPlayerFolder.VideoPlaylist;
			case LaqorrProtobuf.ClientInterface.CmsFileType.TickerContentFile:
				// Ticker content files become redundant with Visual Feeds
				return fileManager.mediaPlayerFolder.VideoPlaylist;
			case LaqorrProtobuf.ClientInterface.CmsFileType.MultiPlaylistFile:
				return fileManager.mediaPlayerFolder.VideoPlaylist;
			case LaqorrProtobuf.ClientInterface.CmsFileType.VisualFeedContent:
				return fileManager.mediaPlayerFolder.VisualFeedContent;
			case LaqorrProtobuf.ClientInterface.CmsFileType.MusicWhitelist:
				return fileManager.mediaPlayerFolder.MusicWhitelist;
			case LaqorrProtobuf.ClientInterface.CmsFileType.PlaylistFile:
				// This could be a video or audio file! We just have to guess
				return fileManager.mediaPlayerFolder.AudioAdvert;
			// case CmsFileType.SoftwareUpdate:
			// This should never happen! We don't apply software updates through the download system
			default:
				return fileManager.mediaPlayerFolder.VideoPlaylist;
		}
	}

	private isProbablyAnAudioScheduleFile(fileName: string) {
		const regex = new RegExp("^[0-9]{8}_[0-9]{8}.Playlist.xml$");
		return regex.test(fileName);
	}

	convertCmsFileTypeToMediaFolder(cmsFileType: LaqorrProtobuf.ClientInterface.CmsFileType, fileName: string): number {
		switch (cmsFileType) {
			case LaqorrProtobuf.ClientInterface.CmsFileType.PlaylistFile:
				// This is a tricky one, due to poor design decisions made pre 2011
				// which we are no locked into
				// We have to determine whether the file is a video playlist or
				// an audio playlist
				// If we get it wrong, it's OK, because we do a better check next time
				if (this.isProbablyAnAudioScheduleFile(fileName)) {
					return fileManager.mediaPlayerFolder.AudioPlaylist;
				} else {
					return fileManager.mediaPlayerFolder.VideoPlaylist;
				}
			default:
				return this.getFileManagerFolderId(cmsFileType);
		}
	}

	workOutMediaFolder(
		cmsFileType: LaqorrProtobuf.ClientInterface.CmsFileType,
		fileEntry: tizen.File | FileEntry
	): Promise<number> {
		// TODO: make sure it reads the file
		// We must write a failing test for this
		return new Promise((resolve) => {
			resolve(this.convertCmsFileTypeToMediaFolder(cmsFileType, fileEntry.name));
		});
	}
}
