import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { VisualFeedBaseComponent } from '../visual-feed-base/visual-feed-base.component';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { FileUtils } from 'src/fileUtils';
import { LoggerService } from "src/loggerService";

const LOG_TAG = 'web-feed.component';

@Component({
    "templateUrl": "web-feed.component.html",
    "selector": "web-feed"
})
export class WebFeedComponent extends VisualFeedBaseComponent implements OnInit, OnDestroy {
    private htmlFeedWatcher: Subscription = null;
    private url: string;
    private refreshIntervalSeconds: number;
    frontFrameUrl: SafeResourceUrl = null;
    backFrameUrl: SafeResourceUrl = null;
    frontFrameIndex: number = 0;
    backFrameIndex: number = 0;
    private isFrontFrame: boolean = true;
    private reloadTimerId = NaN;
    private delayTimeId = NaN;

    constructor(public mediaPlayerFileSystem: MediaPlayerFileSystem, private sanitizer: DomSanitizer, private logger: LoggerService) {
        super(mediaPlayerFileSystem);
    }

    ngOnInit() {
        const webFeedName = "HtmlFeedContent.xml";
        const webFeedFilePath = FileUtils.getFeedFilePath(webFeedName, this.sourceFolder);
        this.logger.logInfo(LOG_TAG, `webFeedFilePath=${webFeedFilePath}`);
        this.htmlFeedWatcher = this.mediaPlayerFileSystem.watchTextContent(
            webFeedFilePath
        ).pipe(
            map(WebFeedComponent.parseFeedContent)
        ).subscribe(
            feedContent => {
                this.logger.logInfo(LOG_TAG, `webFeed ${this.feedId} initializing to url ${feedContent.url}`);
                this.url = feedContent.url;
                this.refreshIntervalSeconds = feedContent.refreshIntervalSeconds;
                this.frontFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
                this.backFrameUrl = null;
                this.isFrontFrame = true;
                this.setReloadTimeout(); 
            }
        );       
    }

    ngOnDestroy() {
        if (this.htmlFeedWatcher)
        {
            this.htmlFeedWatcher.unsubscribe();
            this.htmlFeedWatcher = null;
        }
        this.resetReloadTimer();
    }

    resetReloadTimer() {
        if (this.reloadTimerId) {
            window.clearInterval(this.reloadTimerId);
            this.reloadTimerId = NaN;
        }
        if (this.delayTimeId) {
            window.clearTimeout(this.delayTimeId);
            this.delayTimeId = NaN;
        }
    }
    
    private static parseFeedContent(xmlText:string) : { url: string, refreshIntervalSeconds?: number } {
        if(!xmlText) {
            return {
                url: "",
                refreshIntervalSeconds: NaN
            }
        }
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, "text/xml");

        const url = xmlDoc.getElementsByTagName('Url')[0].textContent;
        const refreshIntervalSecondsText = xmlDoc.getElementsByTagName('RefreshIntervalSeconds')[0].textContent;
        const refreshIntervalSeconds = parseInt(refreshIntervalSecondsText, 10);

        return {
            url: url,
            refreshIntervalSeconds: refreshIntervalSeconds
        }
    }

    private setReloadTimeout() {
        this.resetReloadTimer();        
        if (this.refreshIntervalSeconds) {
            this.reloadTimerId = window.setInterval(
                () => this.reload(Math.min(this.refreshIntervalSeconds * 1000, 3000)),
                this.refreshIntervalSeconds * 1000
            );
        }
    }

    private swapDisplay() {
        if (this.isFrontFrame) {
            this.frontFrameUrl = null;
        } else {
            this.backFrameUrl = null;
        }
        this.isFrontFrame = !this.isFrontFrame;
        this.delayTimeId = NaN;
    }

    private reload(allowedLoadTime: number) {
        if (this.isFrontFrame) {
            this.frontFrameIndex = 2;
            this.backFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            this.backFrameIndex = 1;
        } else {
            this.backFrameIndex = 2;
            this.frontFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            this.frontFrameIndex = 1;
        }
        this.delayTimeId = window.setTimeout(
            this.swapDisplay,
            allowedLoadTime
        );
    }
}