import { Observable } from 'rxjs';
import { scan } from 'rxjs/operators';


function appendToRollingArray<T>(arrayMaxLength: number) : (a : T[], item : T) => T[] {
    return (a : T[], item: T) =>  
        [
            ...(
                a.length < arrayMaxLength
                ? a 
                : a.slice(a.length - arrayMaxLength + 1)
            ), 
            item
        ];
}

export function arrayFromMostRecentValues<T>(maxLength: number) : (input: Observable<T>) => Observable<T[]> {
    return scan(appendToRollingArray(maxLength), []);
}