import { Injectable } from '@angular/core';
import { DeviceSettings, PlayerSettings } from './deviceSettings';
import { HtmlUtils } from './htmlUtils';
import { DeviceReboot } from './deviceReboot';
import { LocalTimeZoneClock, minimumValidDateTime } from './clock';
import { Observable, BehaviorSubject } from "rxjs";
import { map, distinctUntilChanged } from "rxjs/operators";
import { Logger } from './logger';

// DailyReboot
//
// Watches the clock and the settings, and calls Reboot at the 
// most appropriate time
//
// This is harder than you might first think, because the Tizen clock
// can jump around. 
//
// You can get into a situation of a "Reboot Cycle", where the device
// would start up, stay on for a few seconds than reboot again.
// This would happen again and again and again.
//
// See https://github.com/DATMedia/laqorr-tizen/issues/21

type RebootTimerStateId = 
    "Initial" 
    | "StabilityChecking"
    | "AwaitingRebootTime"
    | "Disabled"
    | "AwaitingValidClock";

interface RebootTimerState {
    id: RebootTimerStateId,
    timer?: number,
    expectedClockValue?: Date,
};

const LOG_TAG = "dailyReboot";

async function watchSetting<T>(settings: DeviceSettings, getter: (settings:PlayerSettings) => T) : Promise<Observable<T>> {
    const playerSettings = await settings.getPlayerSettings();
    const subject: BehaviorSubject<T> = new BehaviorSubject<T>(getter(playerSettings));
    settings.playerSettingsObservable.pipe (
        map(getter)
    ).subscribe({
        next: (value) => subject.next(value)
    });
    return subject.pipe(
        distinctUntilChanged()
    );
}


@Injectable({
    providedIn: "root"
})
export class DailyReboot {
    
    private currentState: RebootTimerState = {
        id: "Initial"
    };

    constructor (
        private readonly deviceSettings: DeviceSettings,
        private readonly deviceReboot: DeviceReboot,
        private readonly localTimeZoneClock: LocalTimeZoneClock,
        private readonly logger: Logger
    ) {
    }

    private getMillisecondsToNextReboot(dailyRebootTime: Date) : number {
        dailyRebootTime = new Date(dailyRebootTime.getTime()); // Because we will modify this        
        let dateNow = this.localTimeZoneClock.now;

        while(dailyRebootTime.getTime() < dateNow.getTime()) {
            dailyRebootTime.setDate(dailyRebootTime.getDate() + 1);
        }
        this.logger.debug(LOG_TAG, `current reboot time is ${dailyRebootTime}`);
        const actualDifference = dailyRebootTime.getTime() - dateNow.getTime();
        return actualDifference;
    }

    private onDailyRebootEnabled() : void {
        if (this.currentState.id === 'Disabled') {
            this.transitionToStabilityCheckingStage();
        } 
    }

    private onDailyRebootDisabled() : void {
        console.log(LOG_TAG, `onDailyRebootDisabled invoked. Current state is ${this.currentState.id}`);
        this.currentState = {
            id: "Disabled"
        };
    }

    private dailyRebootTime? : Date;

    private onDailyRebootTimeSet(dailyRebootTime: Date) : void {
        this.logger.debug(LOG_TAG, `onDailyRebootTimeSet invoked. Current state is ${this.currentState.id}`);
        this.dailyRebootTime = dailyRebootTime;
        if (this.currentState.id === 'AwaitingRebootTime') {
            this.enterTimedState(
                "AwaitingRebootTime",
                this.getMillisecondsToNextReboot(this.dailyRebootTime)
            );
        }
    }

    private onClockLeap() : void {
        this.logger.debug(LOG_TAG, `onClockLeap invoked. Current state is ${this.currentState.id}`);
        switch(this.currentState.id) {
            case 'StabilityChecking':
            case 'AwaitingRebootTime':
                this.transitionToStabilityCheckingStage();
                break;
            default:
                break;
        }
    }

    private passesExpectedClockCheck(state: RebootTimerState) : boolean {
        if (!(state.expectedClockValue)) {
            return true;
        }
        return Math.abs(this.localTimeZoneClock.now.getTime() - state.expectedClockValue.getTime()) <= 1000;
    }

    private async onTimeout(timerId: number) : Promise<void> {
        this.logger.debug(LOG_TAG, `onTimeout invoked. Current state is ${this.currentState.id}`);
        if (this.currentState.id === "Disabled") {
            return;
        } else if (timerId !== this.currentState.timer) {
            this.logger.warning(LOG_TAG, `onTimeout, timer mismatch. timerId = ${timerId}, this.currentState.timer = ${this.currentState.timer}`);
            return;
        } else if ((this.currentState.id === "AwaitingValidClock") || !(this.passesExpectedClockCheck(this.currentState))) {
            this.transitionToStabilityCheckingStage();
        } else if (this.currentState.id === "StabilityChecking") {
            // The time is now stable enough to wait for the reboot time!
            this.enterTimedState(
                "AwaitingRebootTime",
                this.getMillisecondsToNextReboot(this.dailyRebootTime)
            );
        } else if (this.currentState.id === "AwaitingRebootTime") {
            try {
                this.logger.debug(LOG_TAG, "*** Invoking Reboot ***");
                await this.deviceReboot.run();    
            } catch (e) {
                this.logger.error(LOG_TAG, `Exception thrown when invoking deviceReboot.run`);
            }
            // Just in case for some reason it fails
            // go back through the whole process again
            //
            // It shouldn't get very far given that the machine is ebooting
            this.transitionToStabilityCheckingStage();
        }
    }

    private async listenToDailyRebootSettings() : Promise<void> {
        const dailyRebootSettingObservable = await watchSetting(this.deviceSettings, settings => settings.dailyReboot);
        dailyRebootSettingObservable.subscribe(
            {
                next: (enabled) => {
                    if (enabled) {
                        this.onDailyRebootEnabled();
                    } else {
                        this.onDailyRebootDisabled();
                    }
                }
            }
        );
        const dailyRebootTimeObservable = await (await watchSetting(this.deviceSettings, settings => settings.dailyRebootTime)).pipe(
            map(HtmlUtils.getTime)
        );
        dailyRebootTimeObservable.subscribe(
            {
                next: (rebootTime) => {
                    this.onDailyRebootTimeSet(rebootTime)
                }
            }
        );
    }

    private listenForLocalTimeZoneClockLeaps() {
        this.localTimeZoneClock.$timeLeap.subscribe({
           next: () => this.onClockLeap()
        });
    }

    private enterTimedState(stateId: RebootTimerStateId, milliseconds : number) {
        this.logger.debug(LOG_TAG, `Entering the timed state ${stateId}. Timer milliseconds: ${milliseconds}`);
        if(this.currentState.timer) {
            try {
                window.clearTimeout(this.currentState.timer);
            } catch (e) {
                this.logger.error(LOG_TAG, `Unexpected exception when clearing timeout ${e}`);
            }
        }
        const timeoutId = window.setTimeout(
            () => this.onTimeout(timeoutId),
            milliseconds
        );
        this.currentState = {
            id: stateId,
            timer: timeoutId,
            expectedClockValue: new Date(this.localTimeZoneClock.now.getTime() + milliseconds)
        }
    }

    private transitionToStabilityCheckingStage() {
        if (new Date().getTime() < minimumValidDateTime.getTime()) {
            this.logger.warning(LOG_TAG, "The clock is before the valid datetime threshold. Not setting the reboot timer");
            this.enterTimedState("AwaitingValidClock", 1000);
        } else {
            this.enterTimedState("StabilityChecking", 60000);
        }
    }         


    public async start() {
        const playerSettings = await this.deviceSettings.getPlayerSettings();
        this.listenToDailyRebootSettings();
        this.listenForLocalTimeZoneClockLeaps();
        if (playerSettings.dailyReboot) {
            this.transitionToStabilityCheckingStage();
        } else {
            this.currentState = {
                id: "Disabled"
            };
        }
    }
}
