import { EneterProtocolFormatter } from "./eneterProtocolFormatter";
import { DuplexChannelEventArgs } from "./duplexChannelEventArgs";
import { DuplexChannelMessageEventArgs } from "./duplexChannelMessageEventArgs";
import { OutputChannel } from "./outputChannel";
import { ProtocolMessage } from "./protocolMessage";
import { Observable, Subject } from "rxjs";
import { LoggerService } from "src/loggerService";

const LOG_TAG = "webSocketDuplextOutputChannel";

// Helper method creating GUID.
function getGuid()
{
    var aGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
            .replace(/[xy]/g, function(c)
            {
                var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });

    return aGuid;
};

export class WebSocketDuplexOutputChannel implements OutputChannel {
    private readonly myChannelId: string;
    private readonly myProtocolFormatter : EneterProtocolFormatter;
    private readonly myResponseReceiverId : string;
    private readonly myTracedObject : string;
    private readonly onConnectionOpenedSubject = new Subject<DuplexChannelEventArgs>();
    private readonly onConnectionClosedSubject = new Subject<DuplexChannelEventArgs>();
    private readonly onConnectionErrorSubject = new Subject<Event>();
    constructor(
        webSocketUri: string,
        responseReceiverId: string,
        private readonly loggerService: LoggerService,
        protocolFormatter? : EneterProtocolFormatter,
    ) {
        this.myChannelId = webSocketUri;
        this.myProtocolFormatter = protocolFormatter || new EneterProtocolFormatter();
        this.myResponseReceiverId = (responseReceiverId) ? responseReceiverId : webSocketUri + "_" + getGuid();
        this.myTracedObject = "WebSocketDuplexOutputChannel " + webSocketUri + " ";
    }
    private myWebSocket : WebSocket;

    public get onConnectionOpened(): Observable<DuplexChannelEventArgs> {
        return this.onConnectionOpenedSubject.asObservable();
    }
    public get onConnectionClosed(): Observable<DuplexChannelEventArgs> {
        return this.onConnectionClosedSubject.asObservable();
    } 
    public get onConnectionError(): Observable<Event> {
        return this.onConnectionErrorSubject.asObservable();
    }

    public onResponseMessageReceived: (args: DuplexChannelMessageEventArgs) => void = (args) => {};

    isConnected() {
        return !!this.myWebSocket;
    }

    openConnection() {
        this.myWebSocket = new WebSocket(this.myChannelId);
        this.myWebSocket.binaryType = "arraybuffer";
        // Must handle webSocket events
        this.myWebSocket.onopen = (evt: Event) => {
            // Ask duplex input channel to open the connection
            // From here
            // https://github.com/DATMedia/Laqorr-Media-Player/blob/master/Chrome/lib/eneter/eneter-messaging-6.5.0.js#L714
            const anEncodedOpenConnection = this.myProtocolFormatter.encodeOpenConnectionMessage(this.myResponseReceiverId, this.loggerService);
            if(anEncodedOpenConnection !== null) {
                this.myWebSocket.send(anEncodedOpenConnection);
            }


            const aDuplexChannelEventArgs = new DuplexChannelEventArgs(this.myChannelId, this.myResponseReceiverId);
            this.onConnectionOpenedSubject.next(aDuplexChannelEventArgs);
        }
        this.myWebSocket.onclose = (evt: Event) => {
            this.closeConnection();
        }
        this.myWebSocket.onmessage = async (evt:MessageEvent<any>) => {
            let aProtocolMessage:ProtocolMessage = null;
            try {
                aProtocolMessage = await this.myProtocolFormatter.decodeMessage(evt.data, this.loggerService);
            }
            catch (err) {
                console.error(`${this.myTracedObject} failed to decode the incoming message.`, err);
                this.loggerService.logError(LOG_TAG, `${this.myTracedObject} failed to decode the incoming message.`);
                this.loggerService.logError(LOG_TAG, err);
                return;
                aProtocolMessage = new ProtocolMessage("Unknown", "", null);
            }

            const aDuplexChannelMessageEventArgs = new DuplexChannelMessageEventArgs(
                this.myChannelId,
                aProtocolMessage.Message,
                this.myResponseReceiverId
            );
            this.onResponseMessageReceived(aDuplexChannelMessageEventArgs);
        }
        this.myWebSocket.onerror = (evt: Event) => {
            this.closeConnection(evt);
        }
    }

        /**
     * Closes connection with the duplex input channel.
     */
    closeConnection(errorEvent?: Event)
    {
        if (this.myWebSocket !== null)
        {
            try
            {
                this.myWebSocket.close();
            }
            catch (err)
            {
            }

            this.myWebSocket = null;
            const aDuplexChannelEventArgs = new DuplexChannelEventArgs(
                this.myChannelId,
                this.myResponseReceiverId
            );
            if(errorEvent) {
                this.onConnectionErrorSubject.next(errorEvent);
            } else {
                this.onConnectionClosedSubject.next(aDuplexChannelEventArgs);
            }
        }
    };

    sendMessage(message : string | ArrayBuffer | Uint8Array | Int8Array | ArrayLike<number>)
    {
        if (this.isConnected() === false)
        {
            const anErrorMsg = this.myTracedObject + "failed to send the message because connection is not open.";
            console.error(anErrorMsg);
            throw new Error(anErrorMsg);
        }
        
        if (message === null)
        {
            const anErrorMsg = this.myTracedObject + "failed to send the message because the message was null.";
            console.error(anErrorMsg);
            throw new Error(anErrorMsg);
        }
        
        if (message.constructor !== String &&
            message.constructor !== ArrayBuffer &&
            message.constructor !== Uint8Array &&
            message.constructor !== Int8Array)
        {
            const anErrorMsg = this.myTracedObject + "failed to send the message because the message is not String or byte[]";
            console.error(anErrorMsg);
            throw new Error(anErrorMsg);
        }

        try
        {
            const anEncodedMessage = this.myProtocolFormatter.encodeRequestMessage(this.myResponseReceiverId, message, this.loggerService);
            this.myWebSocket.send(anEncodedMessage);
        }
        catch (err)
        {
            console.error(this.myTracedObject + "failed to send the message.", err);
            throw err;
        }
    };
}
