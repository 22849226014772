import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { PlayerClockInfoProvider, PlayerClockAdjustment } from './playerClockInfoProvider';

export interface IClock {
    now: Date
}

export class DefaultClock {
    public get now(): Date {
        return new Date();
    }
}

// Set this value to the start date of the server certificate
//
// If the device's clock value comes before this, then Laqorr should
// not do anything until somebody adjusts the time on the device
//
// (Sometimes the device fixes its time spontaneously)
//
export const minimumValidDateTime = new Date("2024-09-07 00:00Z");



function applyAdjustment(d: Date, playerClockAdjustment: PlayerClockAdjustment) {
    if (!playerClockAdjustment) {
        return d;
    }
    const millisecondsSinceEpochDeviceLocal = d.getTime();
    const timeZoneOffsetMinutes = d.getTimezoneOffset();
    const timezoneOffsetMilliseconds = timeZoneOffsetMinutes * 60 * 1000;
    const millisecondsSinceEpochUtc = millisecondsSinceEpochDeviceLocal + timezoneOffsetMilliseconds;
    const millisecondsWithNewAdjustment = millisecondsSinceEpochUtc + playerClockAdjustment.timeZoneOffset + playerClockAdjustment.accuracyAdjustment;
    const newDate = new Date(millisecondsWithNewAdjustment);
    return newDate;
}

function getTotalAdjusmentMilliseconds(clockAdjustment: PlayerClockAdjustment) {
    return clockAdjustment.accuracyAdjustment + clockAdjustment.timeZoneOffset;
}

@Injectable({
    providedIn: "root"
})
export class LocalTimeZoneClock implements IClock {

    private clockAdjustment?: PlayerClockAdjustment;

    private readonly timeLeapSubject = new Subject<void>();


    public constructor(readonly playerClockInfoProvider: PlayerClockInfoProvider) {
        playerClockInfoProvider.$playerClockAdjustment.subscribe({
            next: clockInfo => {
                const invokeTimeLeap = (this.clockAdjustment && (getTotalAdjusmentMilliseconds(this.clockAdjustment) != getTotalAdjusmentMilliseconds(clockInfo)));
                this.clockAdjustment = clockInfo;
                if (invokeTimeLeap) {
                    this.timeLeapSubject.next();
                }
            }
        });
    }

    public get $timeLeap() {
        return this.timeLeapSubject.asObservable();
    }

    public get now(): Date {
        try {
            const date = new Date();
            if (!this.clockAdjustment) {
                return date;
            }
            else {
                return applyAdjustment(date, this.clockAdjustment);
            }
        } catch {
            return new Date();
        }
    }
}