<div id="installedPackagesPageView" class="pageView">
    <div class="pageViewRow" *ngFor="let row of installedItemRows">
        <package-tile
            *ngFor="let package of row"
            [package]="package"
            [takeFocus]="hasFocus && (package == focusedItem)"
            (focusRelinquished)="itemComponentFocusRelinquished(package, $event)"
        >
        </package-tile>
    </div>
</div>