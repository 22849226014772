
<div>
    <table id="logTable">
        <tbody>
            <tr class="{{ log.type }}" *ngFor="let log of logs$ | async;">
                <td>{{ log.time }}</td>
                <td>{{ log.source }}</td>
                <td>{{ log.content }}</td>
            </tr>
        </tbody>
    </table>
</div>
