import { LoggerService } from 'src/loggerService';
import { DynamicDataView } from './dynamicDataView';
import { Utf16LeEncoding, Utf8Encoding, EncoderDecoder, Utf16BeEncoding } from './encoderDecoder';
import { ProtocolMessage } from './protocolMessage';

const LOG_TAG = "eneterProtocolFormatter";

function isArrayBuffer(obj: ArrayBuffer | Blob | string) : obj is ArrayBuffer {
    return obj instanceof ArrayBuffer;
}

function blobToArrayBuffer(blob : Blob, loggerService: LoggerService) : Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>(
        (resolve, reject) =>
        {
            try {
                const reader = new FileReader();
                reader.addEventListener(
                    'loadend',
                    () => {
                        if(isArrayBuffer(reader.result)) {
                            resolve(reader.result);
                        } else {
                            loggerService.logError(LOG_TAG, `blobToArrayBuffer - reader.result if of type ${typeof(reader.result)}`);
                            reject(new Error(`blobToArrayBufer is unable to convert from type ${typeof(reader.result)}`));
                        }
                    } 
                );
                reader.readAsArrayBuffer(blob);
            } catch(error) {
                reject(error);
            }
        }
    );
}


export class EneterProtocolFormatter {  

    static encodeMessage(messageType: number, responseReceiverId: string, messageData: string|ArrayBuffer, loggerService: LoggerService) {
        const anInitialMessageSize = 100;
        const aDynamicDataView = new DynamicDataView(anInitialMessageSize, loggerService);
        const aWriter = new EncoderDecoder(aDynamicDataView);

        // Write message header: ENETER.
        aWriter.writeByte(69);
        aWriter.writeByte(78);
        aWriter.writeByte(69);
        aWriter.writeByte(84);
        aWriter.writeByte(69);
        aWriter.writeByte(82);
        
        aWriter.writeByte(10); // indicates little endian
        aWriter.writeByte(20); // indicates UTF16
        aWriter.writeByte(messageType); // indicate if it is open connection or request message

        const anEncoding = new Utf16LeEncoding();
        
        // responseReceiverId
        aWriter.writePlainString(responseReceiverId, anEncoding, true);
        
        if (messageData !== null)
        {
            if (typeof(messageData) === 'string')
            {
                aWriter.writeByte(20); // indicates the message is string
                aWriter.writePlainString(messageData, anEncoding, true);
            }
            // ArrayBuffer
            else
            {
                aWriter.writeByte(10); // indicates the message is bytes
                aWriter.writePlainByteArray(messageData, true);
            }
        }
        
        return aDynamicDataView.getArrayBuffer();      
    }

    /**
     * Encodes open connection message.
     * @param {String} responseReceiverId id of the client opening the connection.
     */
    encodeOpenConnectionMessage(responseReceiverId : string, loggerService : LoggerService)
    {
        return EneterProtocolFormatter.encodeMessage(10, responseReceiverId, null, loggerService);
    };

    encodeRequestMessage(responseReceiverId : string, messageData : string | ArrayBuffer, loggerService: LoggerService) {
        if (messageData === null)
        {
            throw new Error("Input parameter messageData is null. It must be instance of Strint or ArrayBuffer"); 
        }
        
        return EneterProtocolFormatter.encodeMessage(40, responseReceiverId, messageData, loggerService);
    }


    async decodeMessage(blobOrArrayBuffer: ArrayBuffer | Blob, loggerService: LoggerService) : Promise<ProtocolMessage> {
        // Create like stream reader.

        let arrayBufferMessage : ArrayBuffer;
        if(isArrayBuffer(blobOrArrayBuffer)) {
            arrayBufferMessage = blobOrArrayBuffer;
        } else {
            arrayBufferMessage = await blobToArrayBuffer(blobOrArrayBuffer, loggerService);
        }
        const aDynamicDataView = new DynamicDataView(arrayBufferMessage, loggerService);
        const aReader = new EncoderDecoder(aDynamicDataView);

        // Read header ENETER
        if (aReader.readByte() !== 69 || aReader.readByte() !== 78 || aReader.readByte() !== 69 ||
            aReader.readByte() !== 84 || aReader.readByte() !== 69 || aReader.readByte() !== 82)
        {
            throw new Error("Uknown message header.");
        }

        // Get endianess of the message.
        const anEndianEncodingId = aReader.readByte();
        if (anEndianEncodingId !== 10 && anEndianEncodingId !== 20)
        {
            throw new Error("Uknown endianess.");
        }
        const anIsLittleEndian = anEndianEncodingId === 10;

        // Get string encoding (UTF8 or UTF16)
        const aStringEncodingId = aReader.readByte();
        if (aStringEncodingId !== 10 && aStringEncodingId !== 20)
        {
            throw new Error("Uknown string encoding");
        }

        // Get the message type.
        const aMessageType = aReader.readByte();
         // If it is response message.
         if (aMessageType === 40)
         {
             // Get response receiver id.
             var aSize = aReader.readInt32(anIsLittleEndian);
 
             // Note: we can ignore the response receicer id on the client.
             // Note: the size of response receiver id should be 0 here on the
             // client side.
             aReader.skipBytes(aSize);
 
             // Get message serialization type (string or byte[]).
             var aSerializationType = aReader.readByte();
 
             // If bytes.
             if (aSerializationType === 10)
             {
                 // Get bytes in ArrayBuffer.
                 var aBytes = aReader.readPlainByteArray(anIsLittleEndian);
                 var aProtocolMessage = new ProtocolMessage("MessageReceived", "", aBytes);
 
                 return aProtocolMessage;
             }
 
             // If string.
             if (aSerializationType === 20)
             {
                 var anEncoding;
 
                 // If the incoming string is UTF-8.
                 if (aStringEncodingId === 10)
                 {
                     anEncoding = new Utf8Encoding();
                 }
                 // If the incoming string is UTF-16 LE
                 else if (aStringEncodingId === 20 && anEndianEncodingId === 10)
                 {
                     anEncoding = new Utf16LeEncoding();
                 }
                 // If the incoming string is UTF-16 BE
                 else if (aStringEncodingId === 20 && anEndianEncodingId === 20)
                 {
                     anEncoding = new Utf16BeEncoding();
                 }
                 else
                 {
                     throw new Error("Uknown string encoding of message data.");
                 }
                 
                 var aStr = aReader.readPlainString(anEncoding, anIsLittleEndian);
 
                 var aProtocolMessage = new ProtocolMessage("MessageReceived", "", aStr);
                 return aProtocolMessage;
             }
             
             throw new Error("Uknown type of message data.");
         }
 
         throw new Error("Uknown type of message.");
    }
    
}