<div id="invalidClockViewRoot">
    <div id="imageFrame">
        <img src="/images/laqorr_title.png" />
    </div>
    <div id="informationFrame">

        <div id="dateTimeDisplay">
            <div>{{ dateText$ | async }}</div>
            <div>{{ timeText$ | async }}</div>
        </div>
        <div>
            <p>
                Please adjust the screen's clock settings
            </p>
        </div>
    </div>

</div>