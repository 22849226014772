<div id="keyPressFrame"
    [@recentKeysViewState]="displayKeyPresses ? 'visible' : 'invisible'"
    (@recentKeysViewState.start)="keyPressesAnimationStarted($event)"
    (@recentKeysViewState.done)="keyPressesAnimationDone($event)"
    *ngIf="includeKeyPressesInDom"
>
    <div id="keyPresses">
        <div *ngFor="let keyPress of keyPresses">
            keyCode: {{ keyPress.keyCode }}, key: {{ keyPress.key }}
        </div>
    </div>
</div>