<div id="fileTransferViewRoot">
    {{ statusText$ | async }}
    <div
        id="progressFrame"
        *ngIf="displayProgress$ | async"
    >
        <div id="progressBarFrame">
            <div id="progressBarBorder">
                <div
                    id="progressBarFill"
                    [style.width.%]="progressPercentage$ | async"
                >
                </div>
            </div>
        </div>
        <div id="progressDescription">
            {{ progressDescription$ | async }}
        </div>
    </div>
</div>