import { Component, OnInit } from "@angular/core";
import { Observable, from } from "rxjs";
import { VersionInfoProvider } from 'src/versionInfoProvider';

interface NameValuePair {
    key: string;
    value: string;
}

function isTizen() {
    return (typeof(webapis) !== 'undefined') && !!webapis;
}

function getSystemInfoBuild(): Promise<tizen.SystemInfoBuild> {
    return new Promise<tizen.SystemInfoBuild>(
        (resolve, reject) => {
            tizen.systeminfo.getPropertyValue(
                "BUILD",
                buildInfo => resolve(buildInfo),
                errorCallback => resolve({
                    buildVersion: "",
                    model: "",
                    manufacturer: ""
                })
            );
        }
    );
}

async function getAllValues(versionInfoProvider: VersionInfoProvider) : Promise<Array<NameValuePair>> {
    const result = []
    if (isTizen()) {
        if(typeof(b2bapis) !== 'undefined') {
            result.push({
                key: 'b2bapis.b2bcontrol.version',
                value: b2bapis.b2bcontrol.getVersion()
            });
        };
        if(typeof(webapis) != 'undefined') {
            if (typeof(webapis.avplay) != 'undefined') {
                if (typeof(webapis.avplay.getVersion) == 'function') {
                    result.push({
                        key: 'webapis.avplay.version',
                        value: webapis.avplay.getVersion()
                    })
                }
            }
        }
        const systemInfoBuild = await getSystemInfoBuild();
        result.push({
            key: "buildVersion",
            value: systemInfoBuild.buildVersion
        });
        result.push({
            key: "manufacturer",
            value: systemInfoBuild.manufacturer
        });
        result.push({
            key: "model",
            value: systemInfoBuild.model
        });
    }

    if (window.navigator.userAgent) {
        result.push({
            key: 'userAgent',
            value: window.navigator.userAgent
        });
    }
    const buildVersion = await versionInfoProvider.getVersionInfo();
    result.push({
        key: 'laqorr build',
        value: `${buildVersion.buildDateTime } ${ buildVersion.branch }`
    });
    result.push({
        key: 'laqorr version',
        value: buildVersion.versionNumber
    });
    return result;
}


@Component({
    selector: 'platform-details',
    templateUrl: 'platform-details.component.html',
    styleUrls: ["./platform-details.component.scss"]
})
export class PlatformDetailsComponent implements OnInit  {

    constructor(private readonly versionInfoProvider: VersionInfoProvider) {
    }

    details: Observable<Array<NameValuePair>> = undefined;

    ngOnInit() {
        const getValuesPromise = getAllValues(this.versionInfoProvider);
        this.details = from(getValuesPromise);
    }
}