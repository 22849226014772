<a 
    class="applicationTile"
    [class]="{focused: hasTakenFocus}"
    routerLink="../application/{{ application.id }}"    
    #hyperlink
>
<div 
    class="iconFrame"
    [style.background-image]="'url(' + application.iconPath + ')'"
>
</div>
<div class="tileName">
    {{ application.name }}
</div>
</a>