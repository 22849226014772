import { Component } from '@angular/core';
import { ClientSessionManager} from '../../../eneter/session/clientSessionManager';

@Component({
    selector: 'session-manager-view',
    templateUrl: './session-manager-view.component.html'
})
export class SessionManagerViewComponent {
    constructor(readonly sessionManager: ClientSessionManager) {
    }
}