<div
    id="unsupportedBrowser"
>
    <img 
    src="/images/Cross_red_circle.svg" 
    />
    <div>
        <p>
            This browser does not have the required features to run the Laqorr Web Player.
        </p>
        <p>
            It requires the <span style="font-weight:bold">File System API</span>, which is available on Chrome or Microsoft Edge.
        </p>
        
    </div>
</div>