// This class provides the timezone info
import { Observable, BehaviorSubject, merge } from 'rxjs';
import { filter, map, distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ServerApi, PlayerClockInfo } from './serverApi';
import { DeviceSettings, PlayerSettings } from './deviceSettings';
import { ClientSessionManager, SessionStatusIdentifier } from './eneter/session/clientSessionManager';

enum PcipState {
    Idle,
    IdleWithNoSettingsProvided,
    QueryingWithNoSettingProvided,
    Querying
}

export interface PlayerClockAdjustment {
    accuracyAdjustment: number; // The number of milliseconds to add to utc time due to the clock being wrong
    timeZoneOffset: number; // The number of milliseconds to add to utc time due to the time zone
}

function parseTimeZoneOffset(timeZoneOffsetString: string) : number | undefined {
    if (!timeZoneOffsetString) {
        return undefined;
    }
    let isNegative = false;
    if (timeZoneOffsetString[0] == '-') {
        isNegative = true;
        timeZoneOffsetString = timeZoneOffsetString.substring(1);
    }
    const splitValues = timeZoneOffsetString.split(":").map(s => parseInt(s, 10));
    const multipliers = [3600 * 1000, 60 * 1000, 1000];
    let result = 0;
    for(let i = 0; i < Math.min(splitValues.length, multipliers.length); ++i) {
        result += splitValues[i] * multipliers[i];
    }
    return isNegative ? 0 - result : result;
}

function getLocallySetTimeZoneOffsetInMilliseconds() : number {
    const d = new Date();
    return (0 - d.getTimezoneOffset()) * 60 * 1000;
}



function generateDefaultPlayerClockAdjustment(localOffset?: string) : PlayerClockAdjustment {
    let timeZoneOffset : number | undefined = typeof(localOffset) === 'string' ? parseTimeZoneOffset(localOffset) : undefined;
    if (timeZoneOffset === undefined) {
        timeZoneOffset = getLocallySetTimeZoneOffsetInMilliseconds();
    }

    return {
        accuracyAdjustment: 0,
        timeZoneOffset : timeZoneOffset
    }
}

function generatePlayerClockAdjustment(playerClockInfo: PlayerClockInfo, timeOfResultReceived: number, durationOfCall: number) : PlayerClockAdjustment {
    let timeZoneOffset: number | undefined = parseTimeZoneOffset(playerClockInfo.LocalOffset);
    if (timeZoneOffset === undefined) {
        timeZoneOffset = getLocallySetTimeZoneOffsetInMilliseconds();
    }
    const providedUtcTime = Date.parse(playerClockInfo.UtcTime);
    const expectedUtcTime = timeOfResultReceived - Math.round(durationOfCall/2);
    let adjustment = providedUtcTime - expectedUtcTime;
    // There will always be some difference in the times
    // because of the length of the call
    // The adjustment is only for when there is a huge discrepency
    // otherwise we will just trust the local clock
    if (Math.abs(adjustment) < 2000) {
        adjustment = 0;
    }
    return {
        timeZoneOffset: timeZoneOffset,
        accuracyAdjustment: adjustment
    };
}


@Injectable({
    providedIn: 'root'
})
export class PlayerClockInfoProvider {

    private readonly subject = new BehaviorSubject<PlayerClockAdjustment>(generateDefaultPlayerClockAdjustment());

    $playerClockAdjustment: Observable<PlayerClockAdjustment> = this.subject.asObservable();

    constructor(
        private readonly serverApi: ServerApi, 
        private readonly deviceSettings: DeviceSettings,
        private readonly clientSessionManager: ClientSessionManager
    ) {
        deviceSettings.playerSettingsLoaded.then(
            () => {
                deviceSettings.getPlayerSettings().then(
                    playerSettings => this.applyLoadedPlayerSettings(playerSettings)
                );
            }
        );
        const serverApiTrigger = serverApi.available.pipe(filter(b => b));
        const clientSessionManagerTrigger = clientSessionManager.status.pipe(
            map(s => s.identifier),
            distinctUntilChanged(),
            filter(id => id == SessionStatusIdentifier.connected),
            map(s => true)
        );
        merge(
            serverApiTrigger,
            clientSessionManagerTrigger
        ).pipe(
            throttleTime(10000) // On startup, both triggers fire near simultaneously. We don't want to hammer the server with two calls
        ).subscribe({
            next: () => this.refresh()
        });
    }

    private state: PcipState = PcipState.IdleWithNoSettingsProvided;

    private timerId?: number = undefined;

    private clearTimer() {
        if (typeof(this.timerId) == 'number') {
            window.clearTimeout(this.timerId);
            this.timerId = undefined;
        }
    }

    private resetTimer(duration?:number | undefined) {
        this.clearTimer();
        if(typeof(duration) == 'undefined') {
            duration = 60000;
        }
        window.setTimeout(() => this.refresh(), duration);
    }

    private applyLoadedPlayerSettings(playerSettings: PlayerSettings) {
        switch(this.state) {
            case PcipState.IdleWithNoSettingsProvided:
                this.state = PcipState.Idle;
                this.subject.next(generateDefaultPlayerClockAdjustment(playerSettings.timeZoneOffset));
                break;
            case PcipState.QueryingWithNoSettingProvided:
                this.state = PcipState.Querying;
                this.subject.next(generateDefaultPlayerClockAdjustment(playerSettings.timeZoneOffset));
            default:
                break;
        }
    }

    refresh() {
        switch(this.state) {
            case PcipState.Querying:
            case PcipState.QueryingWithNoSettingProvided:
                break;
            case PcipState.Idle:
            case PcipState.IdleWithNoSettingsProvided:
                this.clearTimer();
                this.state = PcipState.Querying;
                try {
                    const timeAtStart = new Date().getTime();
                    this.serverApi.getPlayerClockInfo().toPromise()
                    .then(
                        result => 
                        {
                            const timeAtReturn = new Date().getTime();
                            console.log(`playerClockInfoProvider: Invoking getPlayerClockInfo took ${timeAtReturn - timeAtStart} milliseconds`);
                            const adjustment : PlayerClockAdjustment = generatePlayerClockAdjustment(result, timeAtReturn, timeAtReturn - timeAtStart);
                            this.state = PcipState.Idle;
                            this.subject.next(adjustment);
                            this.resetTimer(3600000);
                            this.deviceSettings.modifySettings(
                                settings => {
                                    if (settings.timeZoneOffset == result.LocalOffset) {
                                        return false;
                                    } else {
                                        settings.timeZoneOffset = result.LocalOffset;
                                        return true;
                                    }
                                }
                            );
                        },
                        error => {
                            this.state = PcipState.Idle;
                            this.resetTimer(60000);
                        }
                    )
                } catch (e) {
                    this.state = PcipState.Idle;
                    this.resetTimer(60000)
                }
                break;
            default:
                break;
        }
    }
    
}