<div 
    [style.width.px]="rectangle.width" 
    [style.height.px]="rectangle.height"
    [style.left.px]="rectangle.left"
    [style.top.px]="rectangle.top"
    style="position:absolute"
>
    <canvas 
        [ngStyle]="positionAndRotation"
        style="position:absolute;box-sizing: border-box;"
        [attr.width]="positionAndRotation.width"
        [attr.height]="positionAndRotation.height"
        #clockCanvas
    >
    </canvas>
</div>
