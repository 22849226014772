<div 
    id="packageView"
    [class]="{hasFocus: hasFocus}"
>
    <h1>Package View</h1>

    <table>
        <tr>
            <th>Id</th>
            <td>{{ package.id }}</td>
        </tr>
        <tr>
            <th>Name</th>
            <td>{{ package.name }}</td>
        </tr>
        <tr>
            <th>Description</th>
            <td>{{ package.description }}</td>
        </tr>
        <tr>
            <th>Version</th>
            <td>{{ package.version }}</td>
        </tr>
        <tr>
            <th>Total Size</th>
            <td>{{ package.totalSize }}</td>
        </tr>
        <tr>
            <th>Data Size</th>
            <td>{{ package.dataSize }}</td>
        </tr>
        <tr>
            <th>Last Modified</th>
            <td>{{ package.lastModified }}</td>
        </tr>
        <tr>
            <th>Author</th>
            <td>{{ package.author }}</td>
        </tr>
    </table>
    <button
        #btnUninstall
        (click)="uninstallPackage()"
    >
        Uninstall
    </button>
    <div *ngIf="showUninstallProgress">
        <div>Uninstall Progress: {{ uninstallProgress }}</div>
        <div>{{ uninstallError }}</div>
    </div>
</div>


