<div class="pageView">
    <div class="pageViewRow" *ngFor="let row of installedItemRows">
        <application-tile
            *ngFor="let application of row"
            [application]="application"
            [takeFocus]="hasFocus && (application == focusedItem)"
            (focusRelinquished)="itemComponentFocusRelinquished(application, $event)"
        >
        </application-tile>
    </div>
</div>