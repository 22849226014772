import { Component } from '@angular/core';
import { ClientRequestDispatcher } from '../../../eneter/clientRequestDispatcher';

@Component({
    selector: 'client-request-dispatcher-view',
    templateUrl: './client-request-dispatcher-view.component.html'
})
export class ClientRequestDispatcherViewComponent {
    constructor(readonly clientRequestDispatcher: ClientRequestDispatcher) {
    }
}