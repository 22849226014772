<nav
[ngClass]="{
'focused': hasFocus,
'unfocused': !hasFocus
}">
    <a
        routerLink="./customer-component"
        routerLinkActive="active"
        #publicLink
    >
        Public
    </a>
    <a 
        routerLink="./home-component"
        routerLinkActive="active"
        #homeLink
    >
        Home
    </a>
 
    <a
        routerLink="./local-settings-component"
        routerLinkActive="active"
        #localSettingsLink
    >
        Local Settings
    </a>
    <a 
    routerLink="./package-component"
    routerLinkActive="active"
    *ngIf="isRunningOnTizenDevice"
    #packagesLink
>
    Packages
</a>
<a
    routerLink="./application-component"
    routerLinkActive="active"
    *ngIf="isRunningOnTizenDevice"
    #applicationsLink
>
    Applications
</a>
</nav>