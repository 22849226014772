<p>Application view component</p>


<img *ngIf="appInfo" src="{{  appInfo.iconPath }}" />

<table *ngIf="appInfo">
    <tr>
        <th>id</th>
        <td>{{ appInfo.id }}</td>
    </tr>
    <tr>
        <th>name</th>
        <td>{{ appInfo.name }}</td>
    </tr>
    <tr>
        <th>version</th>
        <td>{{ appInfo.version }}</td>
    </tr>
    <tr>
        <th>show</th>
        <td>{{ appInfo.show }}</td>
    </tr>
    <tr>
        <th>categories</th>
        <td>
            <ul>
                <li *ngFor="let category of appInfo.categories">
                    {{ category }}
                </li>
            </ul>
        </td>
    </tr>
    <tr>
        <th>installDate</th>
        <td>{{ appInfo.installDate }}</td>
    </tr>
    <tr>
        <th>size</th>
        <td>{{ appInfo.size }}</td>
    </tr>
    <tr>
        <th>packageId</th>
        <td>{{ appInfo.packageId }}</td>
    </tr>
</table>

<button
    (click)="launchApplication()"
    #launchButton
>
    Launch
</button>
<div *ngIf="displayApplicationLaunchDiagnostics">
    {{ applicationLaunchStatus }}
</div>