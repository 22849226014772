<div 
    class="videoFeedRootElement"
    [style.width.px]="rectangle.width"
    [style.height.px]="rectangle.height"
    [style.left.px]="rectangle.left"
    [style.top.px]="rectangle.top" 
     #container
>
    <!-- the background screen. Inivisible elements can go behind this -->
    <div
        class="video-feed-item"
        [ngStyle]="positionAndRotation"
        style="display:block;z-index:9"
        [style.background-color]="backgroundColor"

    ></div>

    <div
        class="video-feed-item"
        [ngStyle]="positionAndRotation"
        style="display:block;z-index:10"
        *ngIf="displayFileTransferView"
    >
        <file-transfer-view></file-transfer-view>
    </div>

    <div
        class="video-feed-item"
        [ngStyle]="positionAndRotation"
        [style.background-image]="imageDisplayHandler.getSource(imageDisplayHandler.preparedElements[0])"
        [style.display]="imageDisplayHandler.preparedElements[0].display"
        [style.z-index]="imageDisplayHandler.preparedElements[0].zIndex"
        #image1
    ></div>
    <div
        class="video-feed-item"
        [ngStyle]="positionAndRotation"
        [style.background-image]="imageDisplayHandler.getSource(imageDisplayHandler.preparedElements[1])"
        [style.display]="imageDisplayHandler.preparedElements[1].display"
        [style.z-index]="imageDisplayHandler.preparedElements[1].zIndex"
        #image2
    >
    </div>
    <div
        class="image-error-display"
        [ngStyle]="positionAndRotation"
        [style.display]="imageDisplayHandler.errorElement.display"
        [style.z-index]="imageDisplayHandler.errorElement.zIndex"
        #imageError
    > 
        <div>{{ imageDisplayHandler.errorElement.text }}</div>
    </div>

    <video
        class="video-feed-item"
        [ngStyle]="positionAndRotation"
        [style.background-color]="backgroundColor"
        [style.display]="videoDisplayHandler.preparedElements[0].display"
        [style.z-index]="videoDisplayHandler.preparedElements[0].zIndex"
        preload="none"
        #video1
    >
    </video>
    <video
        class="video-feed-item"
        [ngStyle]="positionAndRotation"
        [style.background-color]="backgroundColor"
        [style.display]="videoDisplayHandler.preparedElements[1].display"
        [style.z-index]="videoDisplayHandler.preparedElements[1].zIndex"
        preload="none" 
        #video2
    >
    </video>

    <ng-container *ngIf="isTizen">
        <object
            class="tizen-video-player"
            [style.background-color]="backgroundColor" 
            [style.display]="tizenVideoDisplayHandler.element.display"
            [style.z-index]="tizenVideoDisplayHandler.element.zIndex"
            [style.width.px]="rectangle.width"
            [style.height.px]="rectangle.height"
            type='application/avplayer' 
            #tizenVideo
        >
        </object> 
    </ng-container>
</div>
