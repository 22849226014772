import { DisplayHandler, ExecutionCompleteArgs, PreparedElement, zIndexes, ExecutionId } from './displayHandler';
import { LaqorrFileEntry, FileUtils } from 'src/fileUtils';
import { Subject } from 'rxjs';
import { DeviceSettings } from 'src/deviceSettings';
import { Logger } from "src/logger";
import { NgZone } from '@angular/core';
import { Rectangle } from "src/geometry";
import { calculateTizenVideoDisplayRect } from './calculateTizenVideoDisplayRect';
import { FiniteStateMachineStateManager } from 'src/finiteStateMachineStateManager';
import { LoggerService } from 'src/loggerService';
import { LocalTimeZoneClock } from 'src/clock';

function getAvPlayVersionNumber() : number {
  if (typeof(webapis) != 'undefined') {
    return parseFloat(webapis.avplay.getVersion());
  } else {
    return NaN;
  }
}

const rotationZero: AVPlayRotation = getAvPlayVersionNumber() >= 7.0 
  ? "PLAYER_DISPLAY_ROTATION_NONE"
  : "PLAYER_DISPLAY_ROTATION_0";

const avplayDisplayRotation: AVPlayRotation[] = [
  rotationZero,
  "PLAYER_DISPLAY_ROTATION_270",
  "PLAYER_DISPLAY_ROTATION_180",
  "PLAYER_DISPLAY_ROTATION_90"
];

function degreesToPlayerRotation(degrees: number) : AVPlayRotation {
  return avplayDisplayRotation[Math.round(degrees / 90) % 4];
}

// A wrapper around the AVPlayAPI
interface ITizenVideoDisplayHandlerContext {
  playerPrepare(mediaFile: LaqorrFileEntry) : PromiseLike<void>;
  playerPlay();
  playerStop() : Promise<void>;
  hide() : Promise<void>;
  firePlayComplete(executionCompleteArgs: ExecutionCompleteArgs);
  log(logText: string);
  localTimeZoneClock: LocalTimeZoneClock;
}

interface ITizenVideoDisplayHandlerState {
  onPlayRequested(mediaFile: LaqorrFileEntry, executionId: ExecutionId) : Promise<ITizenVideoDisplayHandlerState>;
  onStopAllRequested() : Promise<ITizenVideoDisplayHandlerState>;
  onStreamCompleted() : Promise<ITizenVideoDisplayHandlerState>;
  onPrepareRequested(mediaFile: LaqorrFileEntry): Promise<ITizenVideoDisplayHandlerState>;
  onError(): Promise<ITizenVideoDisplayHandlerState>;
}

class TizenVideoDisplayHandlerStateIdle implements ITizenVideoDisplayHandlerState {
  constructor(readonly context: ITizenVideoDisplayHandlerContext) {
  }
  async onPlayRequested(mediaFile: LaqorrFileEntry, executionId: ExecutionId) : Promise<ITizenVideoDisplayHandlerState> {
    await this.context.playerPrepare(mediaFile);
    const startDate = this.context.localTimeZoneClock.now;
    this.context.playerPlay();
    return Promise.resolve<ITizenVideoDisplayHandlerState>(
      new TizenVideoDisplayHandlerStatePlaying(this.context, mediaFile, executionId, startDate)
    );
  }

  onStreamCompleted() {
    this.context.log("TizenVideoDisplayHandlerStateIdle.onStreamCompleted unexpectedly invoked");
    return Promise.resolve<ITizenVideoDisplayHandlerState>(this);
  }

  async onPrepareRequested(mediaFile: LaqorrFileEntry) : Promise<ITizenVideoDisplayHandlerState> {
    await this.context.playerPrepare(mediaFile);
    return new TizenVideoDisplayHandlerStatePrepared(this.context, mediaFile);
  }

  onError(): Promise<ITizenVideoDisplayHandlerState> {
    this.context.log('TizenVideoDisplayHandlerStateIdle.onError invoked');
    return Promise.resolve<ITizenVideoDisplayHandlerState>(this);   
  }

  onStopAllRequested() : Promise<ITizenVideoDisplayHandlerState> {
    this.context.log("TizenVideoDisplayHandlerStateIdle.onStopAllRequested unexpectedly invoked");
    return Promise.resolve<ITizenVideoDisplayHandlerState>(this);  
  }
}

class TizenVideoDisplayHandlerStatePrepared implements ITizenVideoDisplayHandlerState {
  constructor(readonly context: ITizenVideoDisplayHandlerContext, readonly mediaFile: LaqorrFileEntry) {
  }

  onStreamCompleted() {
    this.context.log("TizenVideoDisplayHandlerStatePrepared.onStreamCompleted unexpectedly invoked");
    return Promise.resolve<ITizenVideoDisplayHandlerState>(this);
  }

  async onError(): Promise<ITizenVideoDisplayHandlerState> {
    this.context.log('TizenVideoDisplayHandlerStatePrepared.onError invoked');
    await this.context.playerStop();
    this.context.hide();
    return new TizenVideoDisplayHandlerStateIdle(this.context);   
  }

  onStopAllRequested() : Promise<ITizenVideoDisplayHandlerState> {
    this.context.log("TizenVideoDisplayHandlerStatePrepared.onStopAllRequested unexpectedly invoked");
    return Promise.resolve<ITizenVideoDisplayHandlerState>(this);  
  }

  async onPrepareRequested(mediaFile: LaqorrFileEntry) : Promise<ITizenVideoDisplayHandlerState> {
    await this.context.playerPrepare(mediaFile);
    return new TizenVideoDisplayHandlerStatePrepared(this.context, mediaFile);  
  }

  async onPlayRequested(mediaFile: LaqorrFileEntry, executionId: ExecutionId) : Promise<ITizenVideoDisplayHandlerState> {
    const startDate = new Date();
    if(mediaFile.name !== this.mediaFile.name) {
      this.context.log('TizenVideoDisplayHandlerStatePrepared.onPlayRequested. They do not match, so calling playerPrepare again');
      await this.context.playerPrepare(mediaFile);
    }
    this.context.playerPlay();
    return Promise.resolve<ITizenVideoDisplayHandlerState>(
      new TizenVideoDisplayHandlerStatePlaying(this.context, mediaFile, executionId, startDate)
    );
  }
}

class TizenVideoDisplayHandlerPlayingWithFileQueued implements ITizenVideoDisplayHandlerState {
  constructor(
    readonly context: ITizenVideoDisplayHandlerContext,
    readonly mediaFile: LaqorrFileEntry,
    readonly executionId: ExecutionId,
    readonly startTime: Date,
    readonly nextMediaFile: LaqorrFileEntry
  ) {
  }
  async onStreamCompleted() : Promise<ITizenVideoDisplayHandlerState> {
    this.context.log("TizenVideoDisplayHandlerPlayingWithFileQueued.onStreamCompleted invoked");
    await this.context.playerStop();
    this.context.firePlayComplete({
      executionId : this.executionId,
      mediaFile: this.mediaFile,
      startTime: this.startTime,
      succeeded: true
    });
    await this.context.playerPrepare(this.nextMediaFile);
    return new TizenVideoDisplayHandlerStatePrepared(this.context, this.nextMediaFile);
  }
  async onError(): Promise<ITizenVideoDisplayHandlerState> {
    this.context.log('TizenVideoDisplayHandlerPlayingWithFileQueued.onError invoked');
    try {
      await this.context.playerStop();
    } catch {
    } finally {
      this.context.firePlayComplete({
        executionId : this.executionId,
        mediaFile: this.mediaFile,
        startTime: this.startTime,
        succeeded: false
      });
    }
    await this.context.playerPrepare(this.nextMediaFile);
    return new TizenVideoDisplayHandlerStatePrepared(this.context, this.nextMediaFile);
  }

  async onStopAllRequested() : Promise<ITizenVideoDisplayHandlerState> {
    this.context.log("TizenVideoDisplayHandlerPlayingWithFileQueued.onStopAllRequested invoked");
    await this.context.playerStop();
    this.context.hide();
    return new TizenVideoDisplayHandlerStateIdle(this.context);
  }

  onPlayRequested(mediaFile: LaqorrFileEntry, executionId: ExecutionId) : Promise<ITizenVideoDisplayHandlerState> {
    // Should not happen!
    this.context.log('TizenVideoDisplayHandlerPlayingWithFileQueued.onPlayRequested invoked');
    return Promise.resolve<ITizenVideoDisplayHandlerState>(this);  
  }

  onPrepareRequested(mediaFile: LaqorrFileEntry): Promise<ITizenVideoDisplayHandlerState> {
    // I would be unexpected for this to actually happen
    this.context.log('TizenVideoDisplayHandlerPlayingWithFileQueued - onPrepareRequested invoked');
    return Promise.resolve<ITizenVideoDisplayHandlerState>(
      new TizenVideoDisplayHandlerPlayingWithFileQueued(
        this.context,
        this.mediaFile,
        this.executionId,
        this.startTime,
        mediaFile
      )
    );
  }
}

class TizenVideoDisplayHandlerStatePlaying implements ITizenVideoDisplayHandlerState {
  constructor(readonly context: ITizenVideoDisplayHandlerContext, readonly mediaFile: LaqorrFileEntry, readonly executionId: ExecutionId, readonly startTime: Date) {
  }
  async onStreamCompleted() : Promise<ITizenVideoDisplayHandlerState> {
    await this.context.playerStop();
    this.context.hide();
    this.context.firePlayComplete({
      executionId : this.executionId,
      mediaFile: this.mediaFile,
      startTime: this.startTime,
      succeeded: true
    });
    return new TizenVideoDisplayHandlerStateIdle(this.context);
  }

  async onError(): Promise<ITizenVideoDisplayHandlerState> {
    this.context.log('TizenVideoDisplayHandlerStatePlaying.onError invoked');
    try {
      await this.context.playerStop();
      this.context.hide();  
    } catch {
    } finally {
      this.context.firePlayComplete({
        executionId : this.executionId,
        mediaFile: this.mediaFile,
        startTime: this.startTime,
        succeeded: false
      });
    }
    return new TizenVideoDisplayHandlerStateIdle(this.context);
  }

  async onStopAllRequested() : Promise<ITizenVideoDisplayHandlerState> {
    this.context.log("TizenVideoDisplayHandlerStatePlaying.onStopAllRequested invoked");
    await this.context.playerStop();
    this.context.hide();
    return new TizenVideoDisplayHandlerStateIdle(this.context);
  }

  onPlayRequested(mediaFile: LaqorrFileEntry, executionId: ExecutionId) : Promise<ITizenVideoDisplayHandlerState> {
    // Should not happen!
    this.context.log('TizenVideoDisplayHandlerStatePlaying.onPlayRequested invoked');
    return Promise.resolve<ITizenVideoDisplayHandlerState>(this);  
  }

  onPrepareRequested(mediaFile: LaqorrFileEntry): Promise<ITizenVideoDisplayHandlerState> {
    return Promise.resolve<ITizenVideoDisplayHandlerState>(
      new TizenVideoDisplayHandlerPlayingWithFileQueued(
        this.context,
        this.mediaFile,
        this.executionId,
        this.startTime,
        mediaFile
      )
    );
  }
}

export class TizenVideoDisplayHandler implements DisplayHandler, ITizenVideoDisplayHandlerContext {
    private readonly finiteStateMachineManager: FiniteStateMachineStateManager<ITizenVideoDisplayHandlerState>;

    constructor(
      private readonly deviceSettings: DeviceSettings,
      private readonly logger: Logger,
      private readonly ngZone: NgZone, 
      private readonly loggerService: LoggerService,
      readonly localTimeZoneClock: LocalTimeZoneClock
    ) {
      if(typeof(webapis) !== 'undefined') {
        this.playerManager = (webapis || { avplay: undefined}).avplay;
        this.playerManager.setListener({
          onstreamcompleted: () => {
            this.ngZone.run(
              () => {
                this.logger.debug("TizenVideoDisplayHandler", "onstreamcompleted event fired");
                this.finiteStateMachineManager.transition(s => s.onStreamCompleted());
              }
            );
          },
          onerror: (error:AVPlayError) => {
            const errorText = `TizenVideoDisplayHandler.playerManager.onerror triggered. { code='${error.code}', name = '${error.name}', message='${error.message}' }`;
             this.logger.error(
                'TizenVideoDisplayHandler',
                errorText,
                error
              );
              this.log(errorText);
              this.finiteStateMachineManager.transition(s => s.onError());
          }
       });
      }
      this.finiteStateMachineManager = new FiniteStateMachineStateManager<ITizenVideoDisplayHandlerState>(new TizenVideoDisplayHandlerStateIdle(this));
    }

    readonly playCompleteSubject = new Subject<ExecutionCompleteArgs>();
    get mediaExecutionComplete$() {
        return this.playCompleteSubject.asObservable();
    }

    log(text: string) {
      this.logger.debug('TizenVideoDisplayHandler', text);
      this.ngZone.run(
        () => this.loggerService.logDebug(text)
      );
    }

    element: PreparedElement<HTMLObjectElement> = {
        file: null,
        element: null,
        display: "none",
        zIndex: zIndexes.hiding
    }

    get preparedElements() {
        return [this.element];
    }

    readonly playerManager: AVPlayManager;
    stopAll() {
      this.finiteStateMachineManager.transition(state => state.onStopAllRequested());
    }

    initializeRotationAndPosition() {
      try {
        const targetRectangle = this.element.element.parentElement.getBoundingClientRect();
        const converted:Rectangle = {
            left: targetRectangle.left,
            top: targetRectangle.top,
            height: targetRectangle.height,
            width: targetRectangle.width
        };
        const rotation = degreesToPlayerRotation(this.deviceSettings.playerSettings.screenRotation); 
        const transformed = calculateTizenVideoDisplayRect(converted, rotation);
        
        try {
          this.playerManager.setDisplayRect(
            transformed.left,
            transformed.top,
            transformed.width,
            transformed.height
          );        
        }
        catch(error) {
          this.logger.error(`TizenVideoDisplayHandler`, `Error thrown when calling this.playerManager.setDisplayRect(${transformed.left}, ${transformed.top}, ${transformed.width}, ${transformed.height}) ${error}`, error);
          this.loggerService.logError(error);
          return;
        }
        try {
          this.playerManager.setDisplayRotation(
              rotation
          );
        } catch(error) {
          this.logger.error(`TizenVideoDisplayHandler`, `Error thrown when calling this.playerManager.setDisplayRotation: ${error}`, error);
          this.loggerService.logError(error);
        }
    }
    catch(error) {
      this.logger.error(`TizenVideoDisplayHandler`, `Error thrown in initializeRotationAndPosition: ${error}`, error);
      this.loggerService.logError(error);     
    }
  }

    async playerPrepare(mediaFile: LaqorrFileEntry) : Promise<void> {
      const avplayerState = this.playerManager.getState();
      // In ordinary circumstances the state should be 'IDLE'.
      // This might not be the case if the playlist changed on the fly
      // (due to a scheduled change, or a file being added or deleted)
      if(avplayerState === "READY") {
        try {
          this.playerManager.stop();
        } catch(exception) {
          this.logger.error(
            "tizenVideoDisplayHandler",
            "attempt to call stop from the ready state failed",
            exception
          );
        }
        this.logger.debug(
          "tizenVideoDisplayHandler",
          `inside playerPrepare - attempting to call close on the player. Current state is ${avplayerState}`
        );
        try {
          this.playerManager.close();
        } catch(exception) {
          this.logger.error(
            "tizenVideoDisplayHandler",
            `attempt to call close failed`,
            exception
          );
        }
      }
      this.element.file = mediaFile;
      this.element.display = "block";
      try {
        this.playerManager.open(FileUtils.getLaqorrFileEntryUrl(mediaFile));
      } catch (error) {
        this.logger.error(
          "tizenVideoDisplayHandler",
          `playerPrepare threw an error, when calling this.playerManager.open. Current player state is ${avplayerState}`, 
          error
        );
      }
      this.initializeRotationAndPosition();
      try {
        // NOTE: Do not call prepareAsync here.
        // If this mediaFile has been deleted from storage (which is possible)
        // then prepareAsync just fails without invoking a callback.
        // Instead, Calling the synchronous playerManager.prepare method will 
        // trigger the 'onError' call
        this.playerManager.prepare();
      } catch(e) {
        this.logger.error('TizenVideoDisplayHandler', `playerManager.prepare threw an exception`, e);
      }
    }

    playerPlay() {
      this.ngZone.run(
        () => {
          this.element.zIndex = zIndexes.showing;
          this.element.display = "block";
          this.playerManager.setVideoStillMode("false");
          this.playerManager.play();
        }
      );
    }

    hide() {
      return new Promise<void>(
        resolve =>
        {
          this.ngZone.run(
            () => {
              this.element.zIndex = zIndexes.hiding;
              this.element.display = "none";   // It appears that the video display does not respect the z-index 
              this.logger.debug("TizenVideoDisplayHandler", "hide. Set zIndex to hiding, and display to none")
              resolve();
            }
          );
        }
      );
    }

    firePlayComplete(playCompleteArgs: ExecutionCompleteArgs) {
      this.ngZone.run(
        () => this.playCompleteSubject.next(playCompleteArgs)
      );
    }

    playerStop() {
      return new Promise<void>(
        resolve =>
        {
          this.ngZone.run(
            () => {
              this.playerManager.setVideoStillMode("true");
              this.playerManager.stop();
              this.element.file = null;
              this.element.display = "block";
              this.logger.debug("TizenVideoDisplayHandler", "playerStop. Set videoStilMode to true, and display to block");
              resolve();
            }
          );
        }
      )
    }

    async play(mediaFile: LaqorrFileEntry, executionId: ExecutionId)  {
      this.finiteStateMachineManager.transition(
        state => state.onPlayRequested(mediaFile, executionId)
      );
    }

    prepareNext(mediaFile: LaqorrFileEntry) : void {
      this.finiteStateMachineManager.transition(
        state => state.onPrepareRequested(mediaFile)
      );
    }
}