import { Injectable } from '@angular/core';
import { ApplicationLogType, LogData, LogDatabase } from "./logDatabase";
import { LoggerService } from "./loggerService";
import { DeviceSettings } from './deviceSettings';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: "root"
})
export class Logger {
	loggingLevel: ApplicationLogType;
	constructor(
		private logDatabase: LogDatabase,
		private loggerService: LoggerService,
		private deviceSettings: DeviceSettings)
	{
		this.loggingLevel = this.deviceSettings.playerSettings?.loggingLevel;
		this.deviceSettings.playerSettingsObservable
            .pipe(
                filter(playerSettings => playerSettings.loggingLevel != this.loggingLevel)
            ).subscribe({
                next:  (playerSettings) => {
                    this.loggingLevel = playerSettings.loggingLevel;
                }
            });
	}

	async recordLogMessage(logType: ApplicationLogType, source: string, description: string, exception: any) {
		if (logType >= this.loggingLevel) {
			if (exception) {
				if (typeof (exception) === 'string') {
					description = description + '\n"' + exception + '"';
				} else if (exception.message) {
					description = description + '\n"' + exception.message + '"';
					if (exception.stack) {
						description = description + '\n' + exception.stack;
					}
				} else {
					console.log(`Came across an unknown exception! Exception type is ${typeof(exception)}`);
				}
			}

			const logData: LogData = {
				CreatedDate: new Date(),
				EntryType: logType,
				Source: source,
				Description: description
			};
			return await this.logDatabase.recordMessage(logData);
		}
	}

	error(source: string, description: string, exception?: any) {
		this.recordLogMessage(ApplicationLogType.Error, source, description, exception);
		this.loggerService.logError(source, description);
	}

	debug(source: string, description: string, exception?: any) {
		console.log(description);
		this.recordLogMessage(ApplicationLogType.Debug, source, description, exception);
		this.loggerService.logDebug(source, description);
	}

	warning(source: string, description: string, exception?: any) {
		this.recordLogMessage(ApplicationLogType.Warning, source, description, exception);
		this.loggerService.logError(source, description);
	}

	information(source: string, description: string, exception?: any) {
		this.recordLogMessage(ApplicationLogType.Information, source, description, description);
	}
}