<div 
    id="noContentFeedRoot"
    [ngStyle]="positionAndRotation"
    *ngIf="displayElements$ | async"
>
    <div id="imageFrame">
        <img src="/images/laqorr_title.png" />
    </div>
    <div id="textFrame">
        Awaiting content...
    </div>
</div>