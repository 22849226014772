import { Component, OnInit } from '@angular/core';
import { ServerEndpointProvider } from 'src/serverEndpointProvider';
import { NavigatableComponent } from '../../../navigatableComponent';
import { DeveloperModeSetting } from '../../../developerModeSettingProvider';
import { ServerReportingTimer } from 'src/serverReportingTimer';

@Component({
    templateUrl: './home.component.html',
})
export class HomeComponent extends NavigatableComponent implements OnInit {
    status: string;
    socketEndpoint: string;

    supportedKeysQueryStatus: string;
    supportedKeys: tizen.InputDeviceKey[] = [];

    get laqorrServerAddress() : string {
        return this.serverEndPointProvider.serverAddress;
    }

    constructor(
        private readonly serverEndPointProvider: ServerEndpointProvider,
        public readonly developerModeSetting: DeveloperModeSetting,
        private readonly serverReportingTimer: ServerReportingTimer
    ) {
        super();
        this.serverEndPointProvider.endpoints
            .subscribe({
                next: async (endpoints) => {
                    if(endpoints.socketEndpoints.length) {
                        this.socketEndpoint = endpoints.socketEndpoints[0].uri;
                        this.status = null;
                    } else {
                        this.socketEndpoint = '';
                        this.status = `No server endpoints`;
                    }

                }
            });
    }

    private queryForTizenAvailableKeys() {
        if(typeof(tizen) === 'undefined') {
            this.supportedKeysQueryStatus = "The tizen object is not available";
            return;
        }
        if(!(tizen && tizen.tvinputdevice)) {
            return;
        }
        if(tizen.tvinputdevice) {
            this.supportedKeysQueryStatus = "querying for supportedKeys";
            this.supportedKeys = tizen.tvinputdevice.getSupportedKeys();
            this.supportedKeysQueryStatus = `tizen.tvinputdevice.getSupportedKeys() returned ${this.supportedKeys.length} elements`;
        } else {
            this.supportedKeysQueryStatus = "tizen.tvInputDevice not available"
        }
    }

    applyWebServerAddress(address: string) {
        this.serverEndPointProvider.setServerAddress(address);
    }

    ngOnInit() {
        this.queryForTizenAvailableKeys();
    }

    sendUpdateToServer() {
        console.log('sendUpdateToServer has been clicked');
        this.serverReportingTimer.manuallySendUpdate();
    }
}