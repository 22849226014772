import { Component, OnInit, OnDestroy, AfterViewInit, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

function convertToDateString(d: Date) : string {
    return `${d.toLocaleDateString('default', { month: 'long'})} ${d.getDate()}, ${d.getFullYear()}`;
}

function convertToTimeString(d: Date) : string {
    return d.toLocaleTimeString();
}


@Component({
    selector: 'invalid-clock-view',
    templateUrl: './invalid-clock-view.component.html',
    styleUrls: ["./invalid-clock-view.component.scss"]
})
export class InvalidClockViewComponent implements OnInit, OnDestroy {
    private readonly clockSubject = new BehaviorSubject<Date>(new Date())
    public readonly dateText$ = this.clockSubject.pipe(
        map(convertToDateString)
    );
    public readonly timeText$ = this.clockSubject.pipe(map(convertToTimeString));

    private isDestroyed = false;
    private updateTimerId?: number;

    private onTimerFired() {
        if (this.isDestroyed) {
            return;
        }
        const now = new Date();
        this.clockSubject.next(now);
        let next = 1000 - now.getMilliseconds();
        if (next < 100) {
            next += 1000;
        }
        this.updateTimerId = window.setTimeout(() => this.onTimerFired(), next);
    }

    ngOnInit() {
        console.log('invalid-clock-view ngOnInit invoked');
        this.onTimerFired();
    }

    ngOnDestroy(): void {
        this.isDestroyed = true;
        if (this.updateTimerId) {
            window.clearTimeout(this.updateTimerId);
        }
    }
}