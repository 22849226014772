import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs"
import { shareReplay } from "rxjs/operators"
import { arrayFromMostRecentValues } from './customOperators';


// This is used to display the logs that you see on the home screen
// These are not the logs that get sent back to the server

export type LogType = 'INFO' | 'DEBUG' | 'ERROR'

export interface LogEntry {
    type: LogType,
    dateTime: Date,
    content: string,
    source: string
}

@Injectable({
    providedIn: "root"
})
export class LoggerService {
    readonly logs: Observable<LogEntry[]>;

    private readonly logSubject : Subject<LogEntry> = new Subject<LogEntry>();

    constructor() {
        this.logs = this.logSubject.pipe(
            arrayFromMostRecentValues(18),
            shareReplay(1)
        )
        // Subscribing to the logs. We only do this
        // because of the way rxjs works. It will not
        // pipe anything until the first subscription happens
        // 
        // But we want new subscribers to see old logs
        this.logs.subscribe(() => {});
    }

    logInfo(source: string, msg: any)  { this.emit(source, "INFO", msg); }
    logDebug(source: string, msg: any) { this.emit(source, "DEBUG", msg); }
    logError(source: string, msg: any) { this.emit(source, "ERROR", msg); }

    private emit(source: string, logType: LogType, content: any) {
        this.logSubject.next({
            content: `${content}`,
            source: source,
            dateTime: new Date(), // TODO: use the special adjusting clock
            type: logType
        });
    }
}
