import { OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Subscription, fromEvent, pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Directive } from '@angular/core';


export type Side = "Left" | "Right" | "Top" | "Bottom" | "Unknown";

export interface FocusRelinquishedArgs {
    component: NavigatableComponent,
    fromSide: Side
}

@Directive()
export abstract class NavigatableComponent implements OnInit, OnDestroy {
    ngOnInit(): void {
        console.log('NavigatableComponent onInit invoked');
    }

    ngOnDestroy(): void {
        if(this.keyboardEventSubscription) {
            this.keyboardEventSubscription.unsubscribe();
            this.keyboardEventSubscription = null;
        }  
    }

    protected _hasFocus = false;
    public get hasFocus() {
        return this._hasFocus;
    }

    protected keyboardEventSubscription : Subscription;

    relinquishFocus(fromSide: Side) {
        console.log(`navigatableComponent.relinquishFocus(${fromSide}) invoked`);

        this._hasFocus = false;
        if(this.keyboardEventSubscription) {
            this.keyboardEventSubscription.unsubscribe();
            this.keyboardEventSubscription = null;
        }
        this.focusRelinquished.emit({
            component: this,
            fromSide: fromSide
        });
        return true;
    }

    moveFocusLeft() {
        return this.relinquishFocus("Left");
    }

    moveFocusRight() {
        return this.relinquishFocus("Right");
    }

    moveFocusUp() {
        return this.relinquishFocus("Top");
    }

    moveFocusDown() {
        return this.relinquishFocus("Bottom");
    }
    
    public focus(from: Side) {
        console.log(`navigatableComponent.focus(${from}) invoked`);
        this._hasFocus = true;
        if(!this.keyboardEventSubscription) {
            this.keyboardEventSubscription = fromEvent(window, "keydown")
                .pipe(
                    filter(() => this._hasFocus)
                ).subscribe(
                    {
                        next: (event:KeyboardEvent) => 
                        {
                            if(event.key === 'ArrowLeft') {
                                this.moveFocusLeft();
                            } else if (event.key === 'ArrowRight') {;
                                this.moveFocusRight();
                            } else if (event.key === 'ArrowUp') {
                                this.moveFocusUp();
                            } else if (event.key === 'ArrowDown') {
                                this.moveFocusDown();
                            }
                        }
                    }
                );
        }
    }

    @Output()
    public focusRelinquished = new EventEmitter<FocusRelinquishedArgs>();

}