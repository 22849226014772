import { ClientRequestDispatcher } from "../clientRequestDispatcher";
import { ClientCryptoService } from "../clientCryptoService";
import { Subscription } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import * as LaqorrProtobuf from "../../laqorrProtobuf";
import { Serializers } from "../serializers";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { ServerUpdater } from "../../serverUpdater";
import { DeviceReboot } from "../../deviceReboot";
import { DeviceSettings, PlayerSettings, ScreenActivationSettings } from "../../deviceSettings";
import { FileDownloadClient } from "../../fileDownloadClient";
import { ControllerService } from "../../controllerService";
import { FileSynchroniser } from "../../fileSynchroniser";
import { LoggerService } from "../../loggerService";
import { ScreenPowerManager } from "../../screenActivation/screenPowerManager";
import ByteBuffer from "bytebuffer";
import { ActivationInfo } from "../../screenActivation/activationInfo";
import { PlayerClockInfoProvider } from "src/playerClockInfoProvider";

export interface MethodCallDispatcherStatus {
    description: string;
}

const LOG_TAG = "methodCallDispatcher";

// This corresponds with the java code
// com.datmedia.mediaplayerimpl.enter.methodCall.ActionNames
// and with the C# code
// DATMedia.CMS.ServiceInterface\EneterActionNames.cs
const ActionNames = {
    sendStatusUpdate: "sendStatusUpdate",
    restartDevice: "restartDevice",
    synchroniseFiles: "synchroniseFiles",
    updateApp: "updateApp",
    getActivationInfo: "getActivationInfo",
    setActivationInfo: "setActivationInfo",
    activatePlayer: "activatePlayer",
    deactivatePlayer: "deactivatePlayer",
    beginSilentEnrolment: "beginSilentEnrolment",
    getPlayerSettings: "getPlayerSettings",
    setPlayerSettings: "setPlayerSettings",
    queryFolderContents: "queryFolderContents",
    deleteFile: "deleteFile",
    deleteFolder: "deleteFolder",
    clearInProgressFolder: "clearInProgressFolder",
    startDownload: "startDownload",
    completeDownload: "completeDownload",
    receiveBytes: "receiveBytes",
    processPlaylistFolder: "processPlaylistFolder",
    setNetworkConfiguration: "setNetworkConfiguration",
    updateTimeZone: "updateTimeZone"
};

// Convers the PlayerSettings type to the LaqorProtobuf Playersettings type
class PlayerSettingsConversions {
    static screenActivationSettingsToProtobuf(screenActivationSettings: ScreenActivationSettings) : LaqorrProtobuf.ClientInterface.ScreenActivationSettings {
        return {
            ScreenIds: screenActivationSettings.screenId,
            CommunicationPortNames: screenActivationSettings.communicationPortNames,
            DisplayScreenSaver: screenActivationSettings.displayScreenSaver,
            EndTime: screenActivationSettings.endTime,
            InputSource: screenActivationSettings.inputSource,
            ScreenProtocol: screenActivationSettings.screenProtocol,
            ScreenSerialPort: screenActivationSettings.screenSerialPort,
            StartTime: screenActivationSettings.startTime
        };
    }

    static protobufToScreenActivationSettings(screenActivationSettings: LaqorrProtobuf.ClientInterface.ScreenActivationSettings) : ScreenActivationSettings {
        return {
            communicationPortNames: screenActivationSettings.CommunicationPortNames,
            displayScreenSaver: screenActivationSettings.DisplayScreenSaver,
            endTime: screenActivationSettings.EndTime,
            inputSource: screenActivationSettings.InputSource,
            screenId: screenActivationSettings.ScreenIds,
            screenProtocol: screenActivationSettings.ScreenProtocol,
            screenSerialPort: screenActivationSettings.ScreenSerialPort,
            startTime: screenActivationSettings.StartTime
        };
    }

    static playerSettingsToProtobuf(playerSettings: PlayerSettings) : LaqorrProtobuf.ClientInterface.PlayerSettings {
        return {
            AudioVolume: playerSettings.audioVolume,
            AutomaticallyPlayOnStartup: playerSettings.automaticallyPlayOnStartup,
            AutomaticStartupDelaySeconds: playerSettings.automaticStartupDelaySeconds,
            CrossFadeDuration: playerSettings.crossFadeDuration,
            DailyReboot: playerSettings.dailyReboot || false,
            DailyRebootTime: playerSettings.dailyRebootTime,
            DefaultSlideDuration: playerSettings.defaultSlideDuration,
            EnableHealthMonitoring: playerSettings.enableHealthMonitoring,
            HealthCheckIntervalMinutes: playerSettings.healthCheckIntervalMinutes,
            ScheduledAudio: playerSettings.scheduledAudio,
            ScreenRotation: playerSettings.screenRotation,
            TurnScreenOnAndOff: playerSettings.turnScreenOnAndOff || false,
            ScreenActivationSettings: PlayerSettingsConversions.screenActivationSettingsToProtobuf(playerSettings.screenActivationSettings),
            LoggingLevel: playerSettings.loggingLevel
        };
    }
}

type methodCall = (parameters?: ArrayLike<number>) => ArrayLike<number>|Promise<ArrayLike<number>>|void|Promise<void>|Promise<boolean>;

// Listens for incoming method calls
// dispatches them to the appropriate handler
// and then sends the results back
//
// 
@Injectable({
    providedIn: 'root'
})
export class MethodCallDispatcher {

    private readonly statusSubject : BehaviorSubject<MethodCallDispatcherStatus>;
    // serializeInteger
    // num: number
    // output: Uint8Array, using the VARINT encoding and an ID of 1
    //
    // To understand what's going on here:
    // https://developers.google.com/protocol-buffers/docs/encoding
    //
    public static serializeInteger(num : number) {
        const varint32Length = ByteBuffer.calculateVarint32(num);
        let totalBufferLength = varint32Length + 1;
        let byteBuffer = new ByteBuffer(totalBufferLength);
        byteBuffer.writeByte( (1 << 3) | 0);
        byteBuffer.writeVarint32(num);
        return new Uint8Array(byteBuffer.buffer);
    }

    private static convertBooleanToProtobufferArray(v : boolean) {
        const byteBuffer = new ByteBuffer();
        byteBuffer.writeByte(v ? 1 : 0);
        return new Uint8Array(byteBuffer.buffer);
    }

    private static isNumeric(val: number | {}) : val is Number {
        return typeof(val) === 'number';
    }

    private static isBoolean(o:any) : o is boolean {
        return typeof(o) === 'boolean';
    }

    private static readonly emptyByteArray = new Uint8Array(0);    
    
    private static coerceResultIntoProtobufferArray(methodReturnValue : any) {
        // This block of code is coercing the method return value
        // into the expected value
        methodReturnValue = methodReturnValue || MethodCallDispatcher.emptyByteArray;
        if (MethodCallDispatcher.isBoolean(methodReturnValue)) {
            methodReturnValue = MethodCallDispatcher.convertBooleanToProtobufferArray(methodReturnValue);
        }
        return methodReturnValue;
    }

    get status(): Observable<MethodCallDispatcherStatus> {
        return this.statusSubject.asObservable();
    }

    private clientRequestDispatcherMessageSubscription : Subscription;

    constructor(
        private readonly clientRequestDispatcher : ClientRequestDispatcher,
        private readonly clientCryptoService: ClientCryptoService,
        private readonly serverUpdater: ServerUpdater,
        private readonly deviceSettings: DeviceSettings,
        private readonly fileDownloadClient: FileDownloadClient,
        private readonly controllerService: ControllerService,
        private readonly fileSynchroniser: FileSynchroniser,
        private readonly loggerService: LoggerService,
        private readonly screenPowerManager: ScreenPowerManager,
        private readonly deviceReboot: DeviceReboot,
        private readonly playerClockInfoProvider: PlayerClockInfoProvider
    ) {
        this.statusSubject = new BehaviorSubject<MethodCallDispatcherStatus>({
            description: "Has not yet received a method call"
        });

    }

    public listenForIncomingMethodCalls() {
        this.clientRequestDispatcherMessageSubscription = this.clientRequestDispatcher.messageReceived
            .pipe(
                filter(message => message.MessageType == LaqorrProtobuf.MessageType.MethodCall),
                map(
                    message => this.clientCryptoService.decryptUsingSessionKey(message.MessageContents)
                ),
                mergeMap(
                    message => Serializers.MethodCall.MethodInvocation.deserialize(message)
                )
            ).subscribe(
                methodInvocation => this.onMethodInvocation(methodInvocation)
            );
    }

    private async transmitResponse(methodResponse : LaqorrProtobuf.MethodCall.MethodResponse) {
        return this.clientRequestDispatcher.sendMessage({
            MessageType : LaqorrProtobuf.MessageType.MethodResponse,
            MessageContents : this.clientCryptoService.encryptUsingSessionKey(
                await Serializers.MethodCall.MethodResponse.serialize(methodResponse)
            )
        });
    }

    private async getPlayerSettings() : Promise<ArrayLike<number>> {
        const protobufPlayerSettings = PlayerSettingsConversions.playerSettingsToProtobuf(
            await this.deviceSettings.getPlayerSettings()
        );
        return Serializers.ClientInterface.PlayerSettings.serialize(protobufPlayerSettings);
    }

    private async setPlayerSettings(parameters: ArrayLike<number>) : Promise<void> {
        const settings = await Serializers.ClientInterface.PlayerSettings.deserialize(parameters);
        await this.deviceSettings.modifySettings(
            playerSettings => {
                playerSettings.audioVolume = settings.AudioVolume;
                playerSettings.automaticallyPlayOnStartup = settings.AutomaticallyPlayOnStartup;
                playerSettings.automaticStartupDelaySeconds = settings.AutomaticStartupDelaySeconds;
                playerSettings.crossFadeDuration = settings.CrossFadeDuration;
                playerSettings.dailyReboot = settings.DailyReboot;
                playerSettings.dailyRebootTime = settings.DailyRebootTime;
                playerSettings.defaultSlideDuration = settings.DefaultSlideDuration;
                playerSettings.enableHealthMonitoring = settings.EnableHealthMonitoring;
                playerSettings.healthCheckIntervalMinutes = settings.HealthCheckIntervalMinutes;
                playerSettings.scheduledAudio = settings.ScheduledAudio;
                playerSettings.screenActivationSettings = PlayerSettingsConversions.protobufToScreenActivationSettings(
                    settings.ScreenActivationSettings
                );
                playerSettings.screenRotation = settings.ScreenRotation;
                playerSettings.turnScreenOnAndOff = settings.TurnScreenOnAndOff;
                playerSettings.loggingLevel = settings.LoggingLevel;
                return true;
            }
        )
    }

    private async clearInProgressFolder() : Promise<boolean> {
        const result:boolean = await this.fileDownloadClient.clearInProgressFolder();
        return result;
    }

    private async startDownload(parameters: ArrayLike<number>) : Promise<ArrayLike<number>> {
        const downloadParameters = await Serializers.ClientInterface.DownloadParameters.deserialize(parameters);
        this.statusSubject.next({
            description: `startDownload(mediaType=${downloadParameters.MediaType},file=${downloadParameters.File},totalBytes=${downloadParameters.TotalBytes})`
        });
        const result:number = await this.fileDownloadClient.startDownload(downloadParameters);
        // TODO: Use the protobuffer serializers
        // to deserialize the integer result
        if (MethodCallDispatcher.isNumeric(result)) {
            return MethodCallDispatcher.serializeInteger(result);
        }
    }

    private async receiveBytes(parameters: ArrayLike<number>) : Promise<boolean> {
        const receiveBytesParameters = await Serializers.ClientInterface.ReceiveBytesParameters.deserialize(parameters);
        this.statusSubject.next({
            description: `receiveBytes(mediaType=${receiveBytesParameters.MediaType},file=${receiveBytesParameters.File},sequenceId=${receiveBytesParameters.SequenceId})`
        });
        const result:boolean = await this.fileDownloadClient.receiveBytes(receiveBytesParameters);
        return result;
    }

    private async completeDownload(parameters: ArrayLike<number>) : Promise<boolean> {
        const downloadParameters = await Serializers.ClientInterface.DownloadParameters.deserialize(parameters);
        this.statusSubject.next({
            description: `completeDownload(mediaType=${downloadParameters.MediaType},file=${downloadParameters.File},totalBytes=${downloadParameters.TotalBytes})`
        });
        this.loggerService.logDebug(LOG_TAG, 'methodCallDispatcher.completeDownload');
        const result:boolean = await this.fileDownloadClient.completeDownload(downloadParameters);
        return result;
    }

    private static convertActivationInfoToProtobufInterface(activationInfo: ActivationInfo) : LaqorrProtobuf.ClientInterface.ActivationInfo {
        return {
            ActivationState: activationInfo.ActivationState,
            ChangeTime: activationInfo.ChangeTime
                ? activationInfo.ChangeTime.getTime() - (activationInfo.ChangeTime.getTimezoneOffset() * 60 * 1000)
                : 0,
            ChangeTrigger: activationInfo.ChangeTrigger,
            NextChangeTime: activationInfo.NextChangeTime
                ? activationInfo.NextChangeTime.getTime()  - (activationInfo.NextChangeTime.getTimezoneOffset() * 60 * 1000)
                : 0
        };
    }

    private async activatePlayer() : Promise<ArrayLike<number>> {
        const activationInfo =  await this.screenPowerManager.activateManually();
        return Serializers.ClientInterface.ActivationInfo.serialize(
            MethodCallDispatcher.convertActivationInfoToProtobufInterface(activationInfo)
        );
    }

    private async deactivatePlayer() {
        const activationInfo =  await this.screenPowerManager.deactivateManually();
        return Serializers.ClientInterface.ActivationInfo.serialize(
            MethodCallDispatcher.convertActivationInfoToProtobufInterface(activationInfo)
        );
    }

    private restartDevice() {
        return this.deviceReboot.run();
    }
    
    private sendStatusUpdate()  {
        this.serverUpdater.sendUpdate();
    }

    private updateTimeZone() {
        this.playerClockInfoProvider.refresh();
    }

    private processPlaylistFolder() {
        console.log(`ProcessPlaylistFolder invoked. Doing nothing!`);
    }

    private synchroniseFiles() {
        this.fileSynchroniser.synchroniseAllFiles();
    }

    private async queryFolderContents(parameters: ArrayLike<number>): Promise<ArrayLike<number>> {
        const queryPath = await Serializers.ClientInterface.QueryPath.deserialize(parameters);
        const result: LaqorrProtobuf.ClientInterface.FolderQueryResults = await this.controllerService.queryFolderContents(queryPath);
        return Serializers.ClientInterface.FolderQueryResults.serialize(result);
    }

    private async deleteFile(parameters: ArrayLike<number>): Promise<ArrayLike<number>> {
        const deleteFileParameters = await Serializers.ClientInterface.DeleteFileOrFolderParameter.deserialize(parameters);
        return this.controllerService.deleteFile(deleteFileParameters).then(() => {
            return new Uint8Array([1]);
        }, () => {
            return new Uint8Array([0]);
        });
    }

    private async deleteFolder(parameters: ArrayLike<number>): Promise<ArrayLike<number>> {
        const deleteFileParameters = await Serializers.ClientInterface.DeleteFileOrFolderParameter.deserialize(parameters);
        return this.controllerService.deleteFolder(deleteFileParameters).then(() => {
            return new Uint8Array([1]);
        }, () => {
            return new Uint8Array([0]);
        });
    }

    private 

    readonly methodCallLookup: { [key:string]:methodCall } = {
        [ActionNames.getPlayerSettings]: this.getPlayerSettings,
        [ActionNames.setPlayerSettings]: this.setPlayerSettings,
        [ActionNames.sendStatusUpdate]: this.sendStatusUpdate,
        [ActionNames.updateTimeZone]: this.updateTimeZone,
        [ActionNames.synchroniseFiles]: this.synchroniseFiles,
        [ActionNames.queryFolderContents]: this.queryFolderContents,
        [ActionNames.deleteFile]: this.deleteFile,
        [ActionNames.deleteFolder]: this.deleteFolder,
        [ActionNames.clearInProgressFolder]: this.clearInProgressFolder,
        [ActionNames.startDownload]: this.startDownload,
        [ActionNames.receiveBytes]: this.receiveBytes,
        [ActionNames.completeDownload]: this.completeDownload,
        [ActionNames.restartDevice]: this.restartDevice,
        [ActionNames.activatePlayer]: this.activatePlayer,
        [ActionNames.deactivatePlayer]: this.deactivatePlayer,
        [ActionNames.processPlaylistFolder]: this.processPlaylistFolder
    };

    private async onMethodInvocation(invocation: LaqorrProtobuf.MethodCall.MethodInvocation) {
        const methodCall = this.methodCallLookup[invocation.MethodName];
        if(methodCall) {
            this.statusSubject.next({
                description : `Received method invocation: ${invocation.MethodName}`
            });
            try {
                const uncheckedReturnValue = await methodCall.apply(this, [invocation.Parameters]);
                const methodReturnValue = MethodCallDispatcher.coerceResultIntoProtobufferArray(uncheckedReturnValue);
                this.transmitResponse({
                    RequestId: invocation.RequestId,
                    Succeeded: true,
                    Result: Array.from(methodReturnValue)
                });
            } catch (err) {
                const errorMessageString = `Method call failed: ${err}`;
                console.error(errorMessageString);
                this.transmitResponse({
                    RequestId: invocation.RequestId,
                    Succeeded: false,
                    Result: [],
                    ResponseException: {
                        Action: invocation.MethodName,
                        Code: 0,
                        Message: errorMessageString,
                        InnerException: null
                    }
                });
            }
        } else {
            this.statusSubject.next({
                description: `Received (unhandled) method invocation: ${invocation.MethodName}`
            });
            this.transmitResponse({
                RequestId: invocation.RequestId,
                Succeeded: false,
                Result: [],
                ResponseException: {
                    Action: invocation.MethodName,
                    Code: 0,
                    Message: `The method ${invocation.MethodName} has not yet been implemented`,
                    InnerException: null
                }
            });
        }
    }
}