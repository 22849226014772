<div style="width:100%;height:100%;position:absolute;top:0;left:0" #rootElement [ngSwitch]="activationState$ | async">
    <screenlayout-content-view 
        *ngSwitchCase="1"
        [originalHeight]="displayHeight | async" 
        [originalWidth]="displayWidth | async">
    </screenlayout-content-view>
    <div
        *ngSwitchCase="2"
        style="width:100%;height:100%;background-color:black;color:white;display:flex;flex-direction:column;align-items:center;align-content:center;justify-content:space-around"
    >
        <div>
            {{ clockText$ | async }}
        </div>
        <div>
            {{ screenSaverText$ | async }} 
        </div>
    </div>
    <invalid-clock-view
        *ngSwitchCase="3"
    >
   </invalid-clock-view>


</div>