import { Component, OnInit, OnDestroy, AfterViewInit, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, fromEvent, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ScreenPowerManager } from '../../../screenActivation/screenPowerManager';

import { ClientInterface } from '../../../laqorrProtobuf'
import { LocalTimeZoneClock } from '../../../clock';


@Component({
    templateUrl: './display.component.html',
})
export class DisplayComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
    keyPressSubscription : Subscription;

    constructor(readonly router: Router, readonly screenPowerManager: ScreenPowerManager, readonly clock: LocalTimeZoneClock) {
    }

    activationState$: Observable<ClientInterface.ActivationState>;
    screenSaverText$: Observable<string>;
    clockText$: Subject<string> = new Subject<string>();

    ngOnInit() {
        this.keyPressSubscription = fromEvent(window, 'keydown')
            .pipe(
                filter(
                    (event:KeyboardEvent) => 
                        event.key === 'Escape'
                        || event.key === 'XF86Back'
                )
            ).subscribe({
                next: () => {
                    this.router.navigateByUrl('/setup');
                }
            });
        console.log('display.component ngOnInit - subscribing to the screenPowerManager now');
        this.activationState$ = this.screenPowerManager
            .activationInfoObservable
            .pipe(
                tap(value => console.log(`ScreenPowerManager activationInfo - value.ActivationState = ${value.ActivationState}`)),
                map(value => value.ActivationState)
            );
        this.screenSaverText$ = this.screenPowerManager
            .activationInfoObservable
            .pipe(
                map(value => `Next change at ${value.NextChangeTime}`)
            );

        const time = this.clock.now;
        time.getMilliseconds();
        window.setTimeout(
            () => {
                window.setInterval(
                    () => {
                        const clockValue = this.clock.now;
                        this.clockText$.next(clockValue.toString());
                    },
                    1000
                )
            },
            1000 - time.getMilliseconds()
        );
    }

    ngOnDestroy() {
        this.keyPressSubscription.unsubscribe();
        this.keyPressSubscription = undefined;
        this.resizeSubscription.unsubscribe();
    }

    ngAfterContentInit() {
        // console.log('displayComponent.ngAfterContentInit');
        // const elementRef = this.rootElement.nativeElement;
        // this.displayWidth = elementRef.offsetWidth;
        // this.displayHeight = elementRef.offsetHeight;
    }

    resizeSubscription: Subscription;

    private updateDisplayDimensions() {
        const elementRef = this.rootElement.nativeElement;
        this.displayWidth.next(elementRef.offsetWidth);
        this.displayHeight.next(elementRef.offsetHeight);
    }


    ngAfterViewInit() {
        window.setTimeout(
            () => {
                this.updateDisplayDimensions();
            },
            0
        );
        const elementRef = this.rootElement.nativeElement;
        console.log(`displayComponent.ngAfterViewInit. elementRef.offsetWidth=${elementRef.offsetWidth}, elementRef.offsetHeight=${elementRef.offsetHeight}`);
        this.resizeSubscription = fromEvent(window, "resize")
            .subscribe(
                (event) => {
                    console.log('Detected that the element size changed');
                    this.updateDisplayDimensions();
                }
            );
    }

    public displayWidth: Subject<number> = new BehaviorSubject<number>(0);
    public displayHeight: Subject<number> = new BehaviorSubject<number>(0);

    @ViewChild('rootElement') rootElement: ElementRef<HTMLDivElement>;
}