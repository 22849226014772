<div id="rootDiv">
    <top-banner 
        (focusRelinquished) = 'componentFocusRelinquished($event)'
        #topBanner
    ></top-banner>
    <div id="mainBody">
       <navigation-sidebar 
            #navigationSidebar 
            *ngIf="developerModeSetting.value"
            (focusRelinquished) = 'componentFocusRelinquished($event)'
        >
        </navigation-sidebar>
        <div
            id="routerOutlet"
        >
            <router-outlet
                (activate)='onRouteActivated($event)'
                (deactivate)='onRouteDeactivated($event)'
            >
            </router-outlet>
        </div>
    </div>
 
</div>

