<div id="customer-page-root">
    <div id="customer-page-body">

    <enrolment-client-view
        *ngIf="isFileSystemSupported"
        (focusRelinquished)="relinquishFocus($event.fromSide)"
        #enrolmentClientView>
    </enrolment-client-view>

    <unsupported-browser
        *ngIf="!isFileSystemSupported"
    >
    </unsupported-browser>

    </div>
    <div id="customer-page-footer">
        <div>
            <div>Build: </div>
            <div>{{ versionInfo?.buildDateTime }} {{ versionInfo?.branch }}</div>
        </div>
        <div>
            <div>Version:</div>
            <div>{{ versionInfo?.versionNumber }}</div>
        </div>
    </div>
</div>