import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef, NgZone } from '@angular/core';
import { AnimationBuilder } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, merge } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { LaqorrFileEntry } from 'src/fileUtils';
import { VisualFeedBaseComponent } from '../visual-feed-base/visual-feed-base.component';
import { MediaPlayerFileSystem } from 'src/mediaPlayerFileSystem';
import { getVideoFeedFileEntries } from './content/getVideoFeedFileEntries';
import { Logger } from "../../../logger";
import { LoggerService } from "../../../loggerService";
import { PlaylistIterator } from "./playlistIterator";
import { fileManager } from 'src/fileManager';
import { DeviceSettings } from 'src/deviceSettings';
import { FiniteStateMachineStateManager } from 'src/finiteStateMachineStateManager';
import { VideoExecutionRecorder } from './videoExecutionRecorder';
import { ExecutionId, DisplayHandler } from './displayHandler';
import { ElementFader } from './elementFader';
import { ImageDisplayHandler } from './imageDisplayHandler';
import { VideoElementDisplayHandler } from './videoElementDisplayHandler';
import { TizenVideoDisplayHandler } from './tizenVideoDisplayHandler';
import { NullDisplayHandler } from './nullDisplayHandler';
import { LocalTimeZoneClock } from 'src/clock';

function getSystemInfoBuild(): Promise<tizen.SystemInfoBuild> {
    return new Promise<tizen.SystemInfoBuild>(
        (resolve, reject) => {
            tizen.systeminfo.getPropertyValue(
                "BUILD",
                buildInfo => resolve(buildInfo),
                errorCallback => resolve({
                    buildVersion: "",
                    model: "",
                    manufacturer: ""
                })
            );
        }
    );
}

interface VideoFeedStateContext {
    playNextItem: () => Promise<ExecutionId>;
    stopPlaying: () => Promise<void>;
}

abstract class VideoFeedState {
    constructor(protected readonly context: VideoFeedStateContext) {
    }

    abstract itemsAreAvailable() : Promise<VideoFeedState>;
    abstract noItemsAreAvailable() : Promise<VideoFeedState>;
    abstract executionComplete(executionId: ExecutionId) : Promise<VideoFeedState>;
    abstract onComponentDestroy() : Promise<VideoFeedState>;
}

class VideoFeedStatePlaying extends VideoFeedState {
    lastExecutionId: ExecutionId;
    constructor(context: VideoFeedStateContext, lastExecutionId: ExecutionId) {
        super(context);
        this.lastExecutionId = lastExecutionId;
    }
    itemsAreAvailable() {
        return Promise.resolve<VideoFeedState>(this);
    }

    async noItemsAreAvailable() : Promise<VideoFeedState> {
        await this.context.stopPlaying();
        console.log(`VideoFeedStatePlaying.noItemsAreAvailable invoked. Going idle`);
        return new VideoFeedStateIdle(this.context);
    }

    async executionComplete(executionId: ExecutionId) : Promise<VideoFeedState> {
        if(executionId === this.lastExecutionId) {
            this.lastExecutionId = await this.context.playNextItem();
        } else {
            console.log(`VideoFeedStatePlaying.executionComplete. executionId parameter is ${executionId}, this.lastExecutionId = ${this.lastExecutionId}. Ignoring the event`);
        }
        return this;
    }

    async onComponentDestroy() : Promise<VideoFeedState> {
        await this.context.stopPlaying();
        return new VideoFeedStateIdle(this.context);
    }
}

class VideoFeedStateIdle extends VideoFeedState {
    async itemsAreAvailable() : Promise<VideoFeedState> {
        const executionId = await this.context.playNextItem();
        return new VideoFeedStatePlaying(this.context, executionId);
    }

    noItemsAreAvailable() {
        return Promise.resolve(this);
    }

    executionComplete() {
        return Promise.resolve(this);
    }

    async onComponentDestroy() : Promise<VideoFeedState> {
        return Promise.resolve(this);
    }
}

function isTizen() {
    return (typeof(webapis) !== 'undefined') && !!webapis;
}

@Component({
    "templateUrl": "video-feed.component.html",
    "styleUrls": ["video-feed.component.scss"],
    "selector": "video-feed"
})
export class VideoFeedComponent extends VisualFeedBaseComponent implements AfterViewInit, OnDestroy {
    playlistIterator: PlaylistIterator;
    availabilitySubscription : Subscription;
    public readonly imageDisplayHandler: ImageDisplayHandler;
    public readonly videoDisplayHandler: VideoElementDisplayHandler;
    public readonly tizenVideoDisplayHandler: TizenVideoDisplayHandler;
    public readonly nullDisplayHandler: NullDisplayHandler = new NullDisplayHandler();
    enableScheduledAudio: boolean;
    playerVolume: number;
    audioVolumeListener: Subscription;
    readonly isTizen = isTizen();
    displayRect: DOMRect;

    readonly finiteStateMachine : FiniteStateMachineStateManager<VideoFeedState>;

    @ViewChild("container", {read: ElementRef}) container: ElementRef<HTMLDivElement>;
    @ViewChild("image1", {read: ElementRef}) image1: ElementRef<HTMLDivElement>;
    @ViewChild("image2", {read: ElementRef}) image2: ElementRef<HTMLDivElement>;
    @ViewChild("video1", {read: ElementRef}) video1: ElementRef<HTMLVideoElement>;
    @ViewChild("video2", {read: ElementRef}) video2: ElementRef<HTMLVideoElement>;
    @ViewChild("tizenVideo", {read: ElementRef }) tizenVideo: ElementRef<HTMLObjectElement>;

    constructor(
        public mediaPlayerFileSystem: MediaPlayerFileSystem,
        builder: AnimationBuilder,
        private readonly deviceSettings : DeviceSettings,
        private logger: Logger,
        private readonly loggerService: LoggerService,
        private readonly videoExecutionRecorder: VideoExecutionRecorder,
        private readonly localTimeZoneClock: LocalTimeZoneClock,
        sanitizer: DomSanitizer,
        ngZone: NgZone
    ) {
        super(mediaPlayerFileSystem);
        console.log('video-feed.component constructor');
        const elementFader = new ElementFader(builder, deviceSettings);
        this.videoDisplayHandler = new VideoElementDisplayHandler(sanitizer, elementFader, this.localTimeZoneClock);
        this.imageDisplayHandler = new ImageDisplayHandler(deviceSettings, elementFader, this.localTimeZoneClock);
        this.tizenVideoDisplayHandler = new TizenVideoDisplayHandler(deviceSettings, logger, ngZone, this.loggerService, this.localTimeZoneClock);
        const videoFeedStateContext: VideoFeedStateContext = {
            playNextItem: () => this.playNextItem(),
            stopPlaying: () => {
                this.displayFileTransferView = true;
                this.stopAll();
                return Promise.resolve();
            }
        };
        this.finiteStateMachine = new FiniteStateMachineStateManager<VideoFeedState>(
            new VideoFeedStateIdle(videoFeedStateContext)
        );
        this.enableScheduledAudio = this.deviceSettings.playerSettings.scheduledAudio;
        this.playerVolume = this.deviceSettings.playerSettings.audioVolume;
        if (!this.isTizen) {
            this.setUpVolumeHandling();
        }
    }

    executionCompleteSubscription: Subscription;

    ngAfterViewInit() {
        console.log('video-feed.component ngAfterViewInit');
        this.imageDisplayHandler.setNativeElements(this.image1.nativeElement, this.image2.nativeElement);

        if((this.video1) && (this.video2)) {
            this.videoDisplayHandler.setNativeElements(this.video1.nativeElement, this.video2.nativeElement);
        }
        this.displayRect = (<HTMLElement>this.container.nativeElement).getBoundingClientRect();
        if(this.tizenVideo) {
            this.tizenVideoDisplayHandler.element.element = this.tizenVideo.nativeElement;
        }
        const fileEntries$ = getVideoFeedFileEntries(
            this.mediaPlayerFileSystem,
            this.localTimeZoneClock,
            this.sourceFolder,
        ).pipe(share());

        this.playlistIterator = new PlaylistIterator(
            fileEntries$ 
        );

        this.executionCompleteSubscription = merge(
            this.imageDisplayHandler.mediaExecutionComplete$,
            this.videoDisplayHandler.mediaExecutionComplete$,
            this.tizenVideoDisplayHandler.mediaExecutionComplete$
        ).subscribe({
            next: (playedArgs) => {
                this.finiteStateMachine.transition(
                    state => state.executionComplete(playedArgs.executionId)
                );
                if(playedArgs.succeeded) {
                    this.videoExecutionRecorder.record(
                        playedArgs.mediaFile.name,
                        this.feedId,
                        playedArgs.startTime
                    );
                }
            }
        })

        this.availabilitySubscription = this.playlistIterator.availability$.subscribe({
            next: (available) => {
                this.finiteStateMachine.transition(
                    currentState => 
                        available
                            ? currentState.itemsAreAvailable()
                            : currentState.noItemsAreAvailable()
                );
            }
        });
    }

    ngOnDestroy() {
        if(this.executionCompleteSubscription) {
            this.executionCompleteSubscription.unsubscribe();
            this.executionCompleteSubscription = null;
        }
        this.finiteStateMachine.transition(
            currentState => currentState.onComponentDestroy()
        );
        if (this.availabilitySubscription) {
            this.availabilitySubscription.unsubscribe();
            this.availabilitySubscription = null;
        }
        this.playlistIterator.dispose();
        this.playlistIterator = null;
        if (this.audioVolumeListener) {
            this.audioVolumeListener.unsubscribe();
            this.audioVolumeListener = null;
        }
    }

    private setUpVolumeHandling() {
        // If the volume setting change, then 
        this.audioVolumeListener = this.deviceSettings.playerSettingsObservable
            .pipe(
                filter(playerSettings => playerSettings.scheduledAudio != this.enableScheduledAudio || playerSettings.audioVolume != this.playerVolume)
            ).subscribe({
                next:  (playerSettings) => {
                    this.enableScheduledAudio = playerSettings.scheduledAudio;
                    this.playerVolume = playerSettings.audioVolume;
                    this.videoDisplayHandler.setPlayerVolume(
                        this.enableScheduledAudio ? 0.0 : this.playerVolume
                    );
                }
            });
    }

    static readonly generateExecutionId = (
        () => {
            let seed:ExecutionId = 0;
            return () => ++seed;    
        }
    )();

    public displayFileTransferView = true;

    private async playNextItem(): Promise<ExecutionId> {
        if(!this.playlistIterator) {
            this.logger.warning('VideoFeedComponent', 'VideoFeedComponent.playNextItem - playlistIterator is null');
            this.finiteStateMachine.transition(state => state.noItemsAreAvailable());
            return;
        }
        const executionId = VideoFeedComponent.generateExecutionId();
		try {
            const itemToPlay: LaqorrFileEntry = this.playlistIterator.next();
            if (!itemToPlay) {
                this.logger.warning('VideoFeedComponent', `videoFeedComponent.playNextItem - nothing to play`);
                this.finiteStateMachine.transition(state => state.noItemsAreAvailable());
                return;
            }
            this.displayFileTransferView = false;

            (await this.getAppropriateHandlerForFile(itemToPlay)).play(itemToPlay, executionId);
            const nextItemToPlay: LaqorrFileEntry = this.playlistIterator.peekNext();
            if(nextItemToPlay) {
                const nextHandler = await this.getAppropriateHandlerForFile(nextItemToPlay);
                nextHandler.prepareNext(nextItemToPlay);
            }
        } catch (error) {
            this.logger.error('VideoFeedComponent', `Error in playNextItem: ${error}`, error)
        } finally {
            return executionId;
        }
    }
    
    // Picks the appropriate video handler to use
    // depending upon the platform this is running on
    private async selectVideoHandler(): Promise<DisplayHandler> {
        if(isTizen()) {
            if (this.rotation !== 0) {
                return this.tizenVideoDisplayHandler;
            }
            // For some reason the standard HTML video element
            // would fail on tizen 6.5 if there was more than one video
            //
            // On tizen 7.0 the transitions looked bad

            const failingTizenVersions = [
                "tizen 6.5",
                "tizen 7.0"
            ];
            const userAgent = window.navigator.userAgent;
            if (typeof(userAgent) === "string") {
                const userAgentLowered = userAgent.toLowerCase();
                for(let v of failingTizenVersions) {
                    if (userAgentLowered.indexOf(v) != -1) {
                        return this.tizenVideoDisplayHandler;
                    }
                }
            }
            const buildsWeKnowFailWithTheHtmlHandler = [
                "TIZEN-4.0-B2B-TRUNK2018-KantM2-LFD-HOTFIX-RELEASE_20211025.1",
                "TIZEN-4.0-B2B-TRUNK2018-KantM2-LFD-RELEASE_20220622.1",
                "TIZEN-B2B-TRUNK2023-KantSU2e-LFD-HOTFIX-RELEASE_20240410.1",
                "TIZEN-5.0-B2B-TRUNK2019-MuseM-LFD-RELEASE_20230323.2"
            ];
            const buildInfo = await getSystemInfoBuild();
            if (buildsWeKnowFailWithTheHtmlHandler.indexOf(buildInfo.buildVersion) != -1) {
                return this.tizenVideoDisplayHandler;
            }
            if (buildInfo.model === 'OH55A-S') {
                return this.tizenVideoDisplayHandler;
            }
        }
        return this.videoDisplayHandler;
    }

    private async getAppropriateHandlerForFile(file: LaqorrFileEntry): Promise<DisplayHandler> {
        if(!file) {
            return this.nullDisplayHandler;
        }
		const fileType = fileManager.determineFileTypeFromName(file.name);
		if (fileType === fileManager.FILETYPE_IMAGE) {
            return this.imageDisplayHandler;
		}
		else if (fileType === fileManager.FILETYPE_VIDEO) {
            return await this.selectVideoHandler();
        }
        else {
            return this.nullDisplayHandler;
        }
    }
    
    private stopAll() {
        console.log('video-feed.component stopPlayPlaylist invoked');
        for(const handler of [this.imageDisplayHandler, this.tizenVideoDisplayHandler, this.videoDisplayHandler])
        {
            if(handler) {
                handler.stopAll();
            }
        }
    }
}