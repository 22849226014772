<h1>Local settings view</h1>

<table>
    <thead>
        <tr>
            <th>Key</th>
            <th>Value</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let kvp of keyValuePairs$ | async">
            <td>{{ kvp.key }}</td>
            <td>{{ kvp.value }}</td>
        </tr>
    </tbody>
</table>