<table>
    <tbody>
        <tr *ngFor="let kvp of details | async">
            <th>
                {{ kvp.key }}
            </th>
            <td>
                {{ kvp.value }}
            </td>
        </tr>
    </tbody>
</table>