<div class="pager" [class]="{focused: hasFocus}">
    <button 
        [disabled]="!(dataSource.canDecrement | async)" 
        (click)="decrementPage()"
        #decrementButton
    >
        Left
    </button>
    <button
        [disabled]="!(dataSource.canIncrement | async)"
        (click)="incrementPage()"
        #incrementButton
    >
        Right
    </button>
</div>