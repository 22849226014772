<div style="display:flex;">
    <div style="flex:60%;">



        <server-selector 
            *ngIf="developerModeSetting.value"
            [serverAddress] = "laqorrServerAddress" 
            (serverAddressSelected) = "applyWebServerAddress($event)"
            >

        </server-selector> 

        <platform-details></platform-details>

        <client-request-dispatcher-view>
        </client-request-dispatcher-view>

        <br />

        <div style="display:inline-flex;flex-direction: row;align-items:end">
            <enrolment-client-view></enrolment-client-view>
            <button (click)="sendUpdateToServer()" style="margin-left:12px;">
                Send Server update
            </button>
    
        </div>

        <session-manager-view></session-manager-view>

        <div style="position:relative;height:194px">
        <file-transfer-view></file-transfer-view>
        </div>

        <method-call-dispatcher-view></method-call-dispatcher-view>


        <logger-display-view></logger-display-view>

        <h4>Supported Keys</h4>
        {{ supportedKeysQueryStatus }}
        <div>
            <span *ngFor="let supportedKey of supportedKeys">
                [{{supportedKey.code}} - "{{supportedKey.name}}"]
            </span>
        </div>
    </div>
    <div style="flex:40%;">
        <screenlayout-content-view></screenlayout-content-view>
    </div>
</div>
