<div 
    [style.width.px]="rectangle.width" 
    [style.height.px]="rectangle.height"
    [style.left.px]="rectangle.left"
    [style.top.px]="rectangle.top"
    style="position:absolute;">

    <div
         [ngStyle]="positionAndRotation" 
        style="display:flex;justify-content:center;align-items:center;">
        <span>{{typeId}}</span>: <span>{{feedId}}</span>
    </div>

</div>
