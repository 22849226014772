<container-element [ngSwitch]="state">
    <ng-container *ngSwitchCase="'default'">
        <div class='stateAndButtons'>
            <img src="/images/Cross_red_circle.svg" *ngIf="(enrolmentClient?.status | async )?.isError" />
            <span class='enrolmentClientStatus'>{{ (enrolmentClient?.status | async )?.description }}</span>
            <button
                *ngIf="(enrolmentClient?.status | async )?.canEnrol"
                (click)="beginEnrolment()"
                #enrolButton
            >
                Enrol
            </button>
            <button
                *ngIf="(enrolmentClient?.status | async )?.canUnenrol"
                (click)="unenrol()"
                #unenrolButton
            >
                Unenrol
            </button>
    
        </div>

        <div class='uniqueCode'>
            {{ (enrolmentClient?.status | async )?.uniqueCode }}
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'unEnrolConfirmation'">
        <div class="unEnrolConfirmation">
            <p>NOTE: This is an irreversible action. If you unenrol and wish to use Laqorr again, you will need to enrol this device as a new player.</p>
            <p>Do you wish to proceed?</p>
            <div class="buttonRow">
                <button
                    (click)="confirmUnenrol()"
                    #confirmUnenrolButton
                    >
                    Yes
                </button>
                <button
                    (click)="cancelUnenrol()"
                    #cancelUnenrolButton
                >
                    No
                </button>
            </div>
        </div>
    </ng-container>
</container-element>