<div 
    [style.width.px]="rectangle.width" 
    [style.height.px]="rectangle.height"
    [style.left.px]="rectangle.left"
    [style.top.px]="rectangle.top"
    style="position:absolute;">
    <div
        [ngStyle]="positionAndRotation" 
    >
        <iframe *ngIf="frontFrameUrl" [src]="frontFrameUrl" [style.zIndex]="frontFrameIndex" style="position:absolute;width:100%;height:100%;top:0px;left:0px;"></iframe>
        <iframe *ngIf="backFrameUrl" [src]="backFrameUrl" [style.zIndex]="backFrameIndex" style="position:absolute;width:100%;height:100%;top:0px;left:0px;"></iframe>    
    </div>
</div>
