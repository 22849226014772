import { Observable, fromEvent } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { arrayFromMostRecentValues } from './customOperators';



export function watchMostRecentKeydowns(length: number) : Observable<KeyboardEvent[]> {
    return fromEvent(window, "keydown")
        .pipe(
            arrayFromMostRecentValues<KeyboardEvent>(length)
        );
}

export function listenForEasterEgg(...values: string[]) : Observable<void> {
    return watchMostRecentKeydowns(values.length)
        .pipe(
            filter(
                (value:KeyboardEvent[]) => {
                    if(value.length != values.length) {
                        return false;
                    }
                    return value.every(
                        (v, i) => v.key === values[i]
                    );
                }
            ),
            map(() => {}) // Converts it to Observable<void>
        );
}