<div style="display:inline-flex;flex-direction: row;align-items:center;">
    <div style="padding-right:12px">
        {{ (clientRequestDispatcher.status | async)?.description }}
    </div>
    <button *ngIf="(clientRequestDispatcher?.status | async )?.canOpen" (click)="clientRequestDispatcher.openConnection()">
        Open Connection
    </button>
    <button *ngIf="(clientRequestDispatcher?.status | async )?.canClose" (click)="clientRequestDispatcher.closeConnection()">
        Close Connection
    </button>
</div>

